import * as React from "react";
import {ConnectedMapView} from "../components/MapView";
import {Workspace} from "./Workspace";
import { ConnectedToodleNavbar } from "../components/ToodleNavbar";
import {firestore} from "../util/firestore";
import {IGlobalState, ITrip, setNextId} from "../redux/GlobalState";
import {SET_IS_TRIP, UPDATE_USER} from "../redux/actions/sessionActions";
import {
    fetchTripEvents,
    hydrateCurrentTrip,
    moveEventFromDayToWishlist,
    moveEventToDay
} from "../redux/actions/batchedActions";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {DragDropContext, DropResult} from "react-beautiful-dnd";
import {REORDER_WISHLIST} from "../redux/actions/wishlistActions";
import {REORDER_DAY_EVENT} from "../redux/actions/dayActions";
import {ConnectedWishlistFullScreen} from "./WishlistFullScreen";
import {ConnectedWishlist} from "../components/Wishlist";
import {ConnectedDaysList} from "../components/DaysList";
import {Tab, Tabs, Spinner} from "@blueprintjs/core";
import {getActiveTags} from "../redux/selectors";
import {ConnectedDaysScreen} from "./DaysScreen";
import { PlannerScreen } from "./PlannerScreen";
import {PlannerScreenV2} from "./PlannerV2Screen";
import {fetchEvents} from "../redux/actions/boardActions";

interface IState {
    tripNotFound: boolean;
    isHydrated: boolean;
    activeScreen: string;
}
interface IStateProps {
    wishlistLength: number;
}
interface IDispatchProps {
    hydrateCurrentTrip: (trip: ITrip) => void;
    moveEvent: (eventID: string, dayId: number, toIdx: number) => void;
    moveBackToWishlist: (eventID: string) => void;
    reorderWishlist: (fromId: number, toId: number) => void;
    reorderDayEvents: (dayId: number, fromId: number, toId: number) => void;
    fetchTripEvents: (tripID: string) => void;
    setTrip: () => void;
}
type IProps = IDispatchProps & IStateProps;
class MainScreenComp extends React.Component<IProps, IState> {
    state = {
        tripNotFound: false,
        isHydrated: false,
        activeScreen: "map",
    };

    async componentDidMount() {
        this.props.setTrip();
        const docId: string = window.location.pathname.split('/')[2];
        const loadResp = await firestore.loadTrip(docId);
        if (loadResp.trip) {
            setNextId(loadResp.trip.params.nextId);
            this.props.hydrateCurrentTrip(loadResp.trip);
            this.props.fetchTripEvents(loadResp.trip.firestoreId);
            this.setState({isHydrated: true});
        } else {
            console.log(loadResp.err);
            this.setState({tripNotFound: true});
        }
    }


    render() {
        if (!this.state.isHydrated) {
            return <div style={{position: "fixed", left: "50%", top: "50%"}}>
                <Spinner size={Spinner.SIZE_LARGE}/>
            </div>
        }
        return (
            <div className={"main-screen"}>
                <div>
                    <ConnectedToodleNavbar activeScreen={this.state.activeScreen} onViewStateChange={this.changeScreen} isTrip={true}/>
                </div>
                <div className={"main-app"}>
                    <DragDropContext
                        onBeforeDragStart={this.onBeforeDragStart}
                        onDragStart={this.onDragStart}
                        onDragUpdate={this.onDragUpdate}
                        onDragEnd={this.onDragEnd}
                    >
                        <div className={this.state.activeScreen === "map" ? "main-app" : "hide"}><PlannerScreenV2 /></div>
                        <div className={this.state.activeScreen === "plan" ? "main-app" : "hide"}><ConnectedDaysScreen /></div>
                    </DragDropContext>
                </div>
            </div>
        )
    }

    changeScreen = (screen: string) => {
        this.setState({activeScreen: screen});
    }


    onBeforeDragStart = () => {
        console.log("onBeforeDragStart");
    };
    onDragStart = () => {
        console.log("onDragStart");
    };
    onDragUpdate = () => {
        console.log("onDragUpdate");
    };
    onDragEnd = (result: DropResult) => {
        // the only one that is required
        console.log("onDragEnd", result);
        if (result.destination) {
            const eventID = result.draggableId;
            const destinationID = result.destination.droppableId;
            if (result.source.droppableId === result.destination.droppableId) { // dropped in same bucket perhaps a reorder
                console.log("dropped in same bucket");
                if (result.source.index !== result.destination.index) {
                    if (destinationID === "unassigned") {
                        this.props.reorderWishlist(result.source.index, result.destination.index);
                    } else {
                        const dayID = parseInt(destinationID.substr(8));
                        this.props.reorderDayEvents(dayID, result.source.index, result.destination.index);
                    }
                }
            } else {
                console.log("dropped in different bucket");
                if (destinationID === "unassigned") {
                    this.props.moveBackToWishlist(eventID);
                } else if (destinationID.startsWith("dayScrn-") || destinationID.startsWith("dayPlan-")) {
                    console.log("day id to parse is: ", destinationID.substr(8));
                    const dayIdx = parseInt(destinationID.substr(8));
                    console.log("day idx", dayIdx);
                    this.props.moveEvent(eventID, dayIdx, result.destination.index);
                }
                console.log("NEITHER WERE TRIGGERED");

            }
        }
    };

}

const mapDispatchToProps = (dispatch: any): IDispatchProps => {  // TODO: fix dispatch type
    return {
        hydrateCurrentTrip: (trip: ITrip) => {
            dispatch(hydrateCurrentTrip(trip));
        },
        moveEvent: (eventID: string, dayId: number, toIdx: number) => {
            dispatch(moveEventToDay(eventID, dayId, toIdx));
        },
        moveBackToWishlist: (eventID: string) => {
            dispatch(moveEventFromDayToWishlist(eventID))
        },
        reorderWishlist: (fromId: number, toId: number) => {
            dispatch(REORDER_WISHLIST(fromId, toId));
        },
        reorderDayEvents: (dayId: number, fromId: number, toId: number) => {
            dispatch(REORDER_DAY_EVENT(dayId, fromId, toId));
        },
        fetchTripEvents: (tripID: string) => {
            dispatch(fetchTripEvents(tripID));
        },
        setTrip: () => {
            dispatch(SET_IS_TRIP(true));
        }
    };
};
const mapStateToProps = (state: IGlobalState): IStateProps => {
    return {
        wishlistLength: state.currentTrip.wishlist.length,
    };
};

export const MainScreen =  connect(mapStateToProps, mapDispatchToProps)(MainScreenComp);