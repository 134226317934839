import * as React from "react";
import ReactDOM from "react-dom";
import {IEventData} from "../types/types";
import {
    Card,
    Icon,
    Intent,
    Menu, MenuDivider, MenuItem,
    Popover,
    Position,
    Toaster,
    IToaster, ContextMenu, Alert, Dialog,
} from "@blueprintjs/core";
import "../styles/EventCard.css";
import {TagList} from "./TagList";
import {IGlobalState, emptyTripData, ISelectedCard} from "../redux/GlobalState";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {ExpandableText} from "./ExpandableText";
import {ArrowButton} from "./ArrowButton";
import {CheckButton} from "./CheckButton";
import * as _ from "lodash";
import {firestore} from "../util/firestore";
import {ADD_EVENT_DATA_TO_BOARD} from "../redux/actions/boardActions";
import {MouseEvent} from "react";
import {EditableEvent} from "./EditableEvent";

interface IOwnProps {
    eventID: string;
    onClickEvent: () => void;
}
interface IStateProps {
    eventData: IEventData | null;
}
interface IDispatchProps {
    populateEventDataInState: (eventID: string, eventData: IEventData) => void;
}
type IProps = IOwnProps & IStateProps & IDispatchProps;

interface IState {
    cardClass: string;
    isEditing: boolean;
}

class BoardEvent extends React.Component<IProps, IState> {

    private toastRef: IToaster | null = null

    constructor(props: IProps) {
        super(props);
        this.state = {
            cardClass: "event-card",
            isEditing: false,
        }
    }

    public render() {
        if (!this.props.eventData) {
            return (
                <div className={"loading-event"}>
                Loading
                </div>
            );
        } else {
            return (
                <div className={"board-event"} onClick={this.props.onClickEvent}>
                    <div className={"event-image-wrapper"}>
                        <img className={"event-image"} src={this.props.eventData.image}/>
                        <div className={"description"}>
                            {this.props.eventData.description}
                        </div>
                    </div>
                    <div className={"event-details"}>
                        <div className={"event-name"}>
                            {this.props.eventData.name}
                        </div>
                        <div className={"event-bottom"}>
                            <div className={"tags"}>
                                {this.renderTag()}
                            </div>
                        </div>

                    </div>
                    {/*<Alert*/}
                        {/*isOpen={this.state.isDeleteAlertOpen}*/}
                        {/*cancelButtonText={"Cancel"}*/}
                        {/*confirmButtonText={"Delete"}*/}
                        {/*onCancel={() => this.setState({isDeleteAlertOpen: false})}*/}
                        {/*intent={Intent.DANGER}*/}
                        {/*onConfirm={this.deleteEvent}>*/}
                        {/*<p>Are you sure you want to <b>delete </b>the event </p>*/}
                    {/*</Alert>*/}
                    {/*{this.renderEditEvent()}*/}
                </div>
            );
        }

    }
    renderTag = () => {
        if (this.props.eventData) {
            return (
                <TagList selected={[]} tags={this.props.eventData.tags} interactive={false}/>
            );
        }
    }
    //
    // handleRightClick = (e: MouseEvent) => {
    //     e.preventDefault();
    //     ContextMenu.show(this.renderContextMenu(), {left: e.clientX, top: e.clientY})
    // }
    // handleDoubleClick = (e: MouseEvent) => {
    //     this.setState({isEditing: true});
    // }
    // public renderContextMenu() {
    //     // return a single element, or nothing to use default browser behavior
    //     return (
    //         <Menu>
    //             <MenuItem icon={"edit"} onClick={() => this.setState({isEditing: true})} text="Edit" />
    //             {this.props.eventIsinWishlist ?
    //                 null
    //                 :
    //                 <MenuItem icon={"arrow-left"} onClick={this.backToWishlist} text="Back to wishlist"/>
    //             }
    //             <MenuItem icon={"delete"} onClick={this.openDeleteAlert} text="Delete"/>
    //         </Menu>
    //     );
    // }
    //
    // private renderEditEvent = () => {
    //     return (
    //         <Dialog
    //             isOpen={this.state.isEditing}
    //             canEscapeKeyClose={false}
    //             canOutsideClickClose={false}
    //             enforceFocus={true}
    //             style={{width: "750px"}}
    //         >
    //             <EditableEvent isEdit inList={false} onCancel={() => this.setState({isEditing: false})} addToWishlist={this.updateEvent} eventData={this.props.eventData!}/>
    //         </Dialog>
    //     )
    // }
    //
    // private updateEvent = (event: IEventData) => {
    //     this.props.updateEventData(event);
    //     this.setState({isEditing: false});
    // }


}

const mapStateToProps = (state: IGlobalState, ownProps: IOwnProps): IStateProps => {
    return {
        eventData: state.currentBoard.eventData[ownProps.eventID],
    };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: IOwnProps): IDispatchProps => {
    return {
        populateEventDataInState: (eventID: string, eventData: IEventData) => {
            dispatch(ADD_EVENT_DATA_TO_BOARD(eventID, eventData));
        },
    };
};

export const ConnectedBoardEvent = connect(mapStateToProps, mapDispatchToProps)(BoardEvent);
