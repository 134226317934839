import * as React from "react";
import {Spinner} from "@blueprintjs/core";
import {firestore, isFirebaseError} from "../util/firestore";
import {emptyTripData, IGlobalState, ITrip} from "../redux/GlobalState";
import { Dispatch } from "redux";
import { hydrateCurrentTrip } from "../redux/actions/batchedActions";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import {IGooglePlaceDetails} from "../types/types";

interface IDispatchProps {
    hydrateCurrentTrip(currentTrip: ITrip): void,
}

interface IState {
    newLink: string;
}

interface IStateProps {
    tripLocation: IGooglePlaceDetails | null;
}

type IProps = IDispatchProps & IStateProps;

export class WaitingScreen extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            newLink: "",
        }
    }

    async componentDidMount() {
        // const currentTrip = await firestore.initTrip();
        if (this.props.tripLocation) { // if user didn't specify a location, take them back to landing page; by not doing anything
            const currentTrip = await firestore.newTrip(this.props.tripLocation);
            if (currentTrip) {
                this.props.hydrateCurrentTrip(currentTrip);
                this.setState({newLink: currentTrip.firestoreId});
            } else {
                alert("Something's wrong... Can't make a new a trip for you :(")
            }
        }
    }
    render() {
        if (!this.props.tripLocation) {
            return (<Redirect to={"/"}/>);
        }
        return this.state.newLink
            ? <Redirect to={`/trip/${this.state.newLink}`}/>
            : <div style={{position: "fixed", left: "50%", top: "50%"}}> <Spinner size={Spinner.SIZE_LARGE} /> </div>;
    }

}

const mapStateToProps = (state: IGlobalState): IStateProps => {
    return {
        tripLocation: state.currentTrip.params.tripLocation,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
    return {
        hydrateCurrentTrip: (currentTrip: ITrip) => {
            dispatch(hydrateCurrentTrip(currentTrip));
        }
    };
};

export const ConnectedWaitingScreen = connect(mapStateToProps, mapDispatchToProps)(WaitingScreen);