import * as React from "react";
import {Icon, Intent, Tag} from "@blueprintjs/core";
import "../styles/EventCard.css";
import * as _ from "lodash";
import "../styles/TagList.css";
import {InputText} from "./InputText";

interface IProps {
    hide: boolean;
}
export class ReservationForm extends React.Component<IProps, never> {

    render() {
        return (
            <div className={this.props.hide ? "hide" : "reservation-page"}>
                <div className={"reservation-form"}>
                    <InputText placeholder={"Full Name"}/>
                    <InputText placeholder={"Email"}/>
                    <InputText placeholder={"Confirm Email"}/>
                    <InputText placeholder={"Credit Card Number"}/>
                </div>
            </div>
        );
    }
}
