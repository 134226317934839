import * as React from "react";
import ReactDOM from "react-dom";
import {IEventData} from "../types/types";
import {
    Card,
    Icon,
    Intent,
    Menu, MenuDivider, MenuItem,
    Popover,
    Position,
    Toaster,
    IToaster,
} from "@blueprintjs/core";
import "../styles/EventCard.css";
import {TagList} from "./TagList";
import {IGlobalState, emptyTripData, ISelectedCard} from "../redux/GlobalState";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {ExpandableText} from "./ExpandableText";
import {ArrowButton} from "./ArrowButton";
import {moveEventFromDayToWishlist, moveEventToDay} from "../redux/actions/batchedActions";
import { SET_SELECTED_CARD } from "../redux/actions/sessionActions";
import {CheckButton} from "./CheckButton";
import * as _ from "lodash";

interface IOwnProps {
    selectedEventID: string;
    unselectEvent: () => void;
}
interface IStateProps {
    eventData: IEventData;
}
interface IDispatchProps {
}
type IProps = IOwnProps & IStateProps & IDispatchProps;

interface IState {
    cardClass: string;
}

class EventDetails extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            cardClass: "event-card",
        }
    }

    public render() {
        if (!this.props.eventData) {
            return null;
        }
        const locationName = this.props.eventData.location.name ? this.props.eventData.location.name : this.props.eventData.location.googlePlaceDetails ? this.props.eventData.location.googlePlaceDetails.address : "";
        let cardClass = "event-card";
        return (
            <div>
                <Card
                    className={cardClass}
                    interactive={true}
                    elevation={1}
                >
                    <div className={"event-card-top"}>
                        {this.props.unselectEvent ? <Icon icon={"cross"} onClick={this.props.unselectEvent} /> : null}
                        <div className={"event-title"}>
                            {this.props.eventData.name}
                        </div>
                        <div className={"event-source"}>
                            <a href={this.props.eventData.source_url} rel="noopener noreferrer" target="_blank"> {this.props.eventData.source} </a>
                        </div>
                    </div>
                    <div className={"event-card-bottom"}>
                        <div className={"event-image-wrapper"}>
                            <img className={"event-image"} src={this.props.eventData.image}/>
                        </div>
                        <div className={"event-body"}>
                            <ExpandableText className={"event-details"} lineLimit={3} text={this.props.eventData.description} />
                            <hr className={"event-divider"}/>
                            <div className={"event-tags"}>
                                {/*<div className={"event-duration-tag"}>*/}
                                {/*/!*<object type="image/svg+xml" data="icons/time-icon.svg" className="event-icon" />*!/*/}
                                {/*<Tag*/}
                                {/*className={"tag"}*/}
                                {/*interactive={false}*/}
                                {/*round={true}*/}
                                {/*intent={Intent.WARNING}*/}
                                {/*>*/}
                                {/*{this.props.event.duration}*/}
                                {/*</Tag>*/}
                                {/*</div>*/}
                                <div className={"event-taglist"}>
                                    {/*<object type="image/svg+xml" data="icons/globe.svg" className="event-icon" />*/}
                                    <TagList selected={[]} tags={this.props.eventData.tags} interactive={false} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"event-footer"}>
                        <div className={"event-location"}>
                            <Icon iconSize={11} icon={"map-marker"} className={"event-loc-pin"}/>
                            <div className={"event-loc-name"}>{locationName}</div>
                        </div>
                    </div>
                </Card>
            </div>
        )
    }


}

const mapStateToProps = (state: IGlobalState, ownProps: IOwnProps): IStateProps => {
    return {
        eventData: state.currentBoard.eventData[ownProps.selectedEventID],
    };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: IOwnProps): IDispatchProps => {
    return {
    };
};

export const ConnectedEventDetails = connect(mapStateToProps, mapDispatchToProps)(EventDetails);
