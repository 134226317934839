import * as React from "react";
import {ItemRenderer} from "@blueprintjs/select";
import {MenuItem} from "@blueprintjs/core";

export interface IGooglePlaceSuggestion{
    description: string;
    id: string;
    place_id: string;
    terms: {offset: string; value: string}[];
}
// const allLocations: IGooglePlaceSuggestion[] = (locations as any).locations;

export const renderLocation: ItemRenderer<IGooglePlaceSuggestion> = (location, { handleClick, modifiers, query }) => {
    if (!modifiers.matchesPredicate) {
        return null;
    }
    const text = location.description;
    return (
        <MenuItem
            active={modifiers.active}
            disabled={modifiers.disabled}
            key={location.id}
            onClick={handleClick}
            text={highlightText(text, query)}
            className={"location-name-entry"}
        />
    );
};

function highlightText(text: string, query: string) {
    let lastIndex = 0;
    const words = query
        .split(/\s+/)
        .filter(word => word.length > 0)
        .map(escapeRegExpChars);
    if (words.length === 0) {
        return [text];
    }
    const regexp = new RegExp(words.join("|"), "gi");
    const tokens: React.ReactNode[] = [];
    while (true) {
        const match = regexp.exec(text);
        if (!match) {
            break;
        }
        const length = match[0].length;
        const before = text.slice(lastIndex, regexp.lastIndex - length);
        if (before.length > 0) {
            tokens.push(before);
        }
        lastIndex = regexp.lastIndex;
        tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
    }
    const rest = text.slice(lastIndex);
    if (rest.length > 0) {
        tokens.push(rest);
    }
    return tokens;
}

function escapeRegExpChars(text: string) {
    return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
}

export const locationSelectProps = {
    // itemPredicate: filterLocation,
    itemRenderer: renderLocation,
};

export function areLocationsEqual(locationA: IGooglePlaceSuggestion, locationB: IGooglePlaceSuggestion) {
    // Compare only the titles (ignoring case) just for simplicity.
    return locationA.id.toLowerCase() === locationB.id.toLowerCase();
}

export function doesLocationEqualQuery(location: IGooglePlaceSuggestion, query: string) {
    return location.description.toLowerCase() === query.toLowerCase();
}

export function arrayContainsLocation(locations: IGooglePlaceSuggestion[], locationToFind: IGooglePlaceSuggestion): boolean {
    return locations.some((location: IGooglePlaceSuggestion) => location.id === locationToFind.id);
}

export function addLocationToArray(locations: IGooglePlaceSuggestion[], locationToAdd: IGooglePlaceSuggestion) {
    return [...locations, locationToAdd];
}

export function deleteLocationFromArray(locations: IGooglePlaceSuggestion[], locationToDelete: IGooglePlaceSuggestion) {
    return locations.filter(loc => loc !== locationToDelete);
}