import * as React from "react";
import {IBoard, ITrip} from "../redux/GlobalState";
import {firestore} from "../util/firestore";
import {NonIdealState, Spinner} from "@blueprintjs/core";
import {ConnectedLandingNavbar} from "../components/LandingNavbar";


interface IState {
    boards: IBoard[];
    trips: ITrip[];
    isLoadingTrips: boolean;
    isLoadingBoards: boolean;
}
export class AccountScreen extends React.Component<{}, IState> {
    state = {
        boards: [] as IBoard[],
        trips: [] as ITrip[],
        isLoadingTrips: true,
        isLoadingBoards: true,
    }

    componentDidMount() {
        firestore.auth.onAuthStateChanged(user => {
            if (user && user.email) {
                console.log("authenticated with user in app: ", user);
                firestore.boards.where('owner', '==', user.email).get()
                    .then((validBoards) => {
                        console.log("valid Boards: ", validBoards);
                        if (validBoards.empty) {
                            this.setState({isLoadingBoards: false});
                        } else {
                            const boardsList = [] as IBoard[];
                            validBoards.forEach((b) => {
                                boardsList.push(b.data() as IBoard);
                            });
                            this.setState({boards: boardsList, isLoadingBoards: false});
                        }
                    })

                firestore.trips.where('owner', '==', user.email).get()
                    .then((validTrips) => {
                        console.log("valid Trips: ", validTrips);
                        if (validTrips.empty) {
                            this.setState({isLoadingTrips: false});
                        } else {
                            const tripList = [] as ITrip[];
                            validTrips.forEach((t) => {
                                tripList.push(t.data() as ITrip);
                            });
                            this.setState({trips: tripList, isLoadingTrips: false});
                        }
                    })
            }
        });
    }

    render() {
        return (
            <div className={"account-screen"}>
                <ConnectedLandingNavbar />
                <div className={"account-main"}>
                    <div className={"section"}>
                        <div className={"title"}> Boards </div>
                        <div className={"links"}>
                            {this.boardsToList()}
                        </div>
                    </div>
                    <div className={"section"}>
                        <div className={"title"}> Trips </div>
                        <div className={"links"}>
                            {this.tripsToList()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    boardsToList() {
        if (this.state.isLoadingBoards) {
            return <Spinner />;
        } else if (this.state.boards.length === 0) {
            return <NonIdealState icon={"folder-open"} title="You don't have any boards"/>
        } else {
            return this.state.boards.map((board, i) => {
                return <div key={i} className={"link-element"}>
                    <a target={"__blank"} href={"/board/" + board.firestoreId}>{board.boardName}</a>
                </div>
            });
        }
    }

    tripsToList() {
        if (this.state.isLoadingTrips) {
            return <Spinner />;
        } else if (this.state.boards.length === 0) {
            return <NonIdealState icon={"folder-open"} title="You don't have any trips"/>
        } else {
            return this.state.trips.map((trip, i) => {
                return <div key={i} className={"link-element"}>
                    <a target={"__blank"} href={"/trip/" + trip.firestoreId}>{trip.params.tripName}</a>
                </div>
            });
        }
    }
}