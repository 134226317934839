import * as React from "react";
import "../styles/EventCard.css";
import GoogleMapReact from "google-map-react";
import {connect} from "react-redux";
import {IGlobalState, ISelectedCard} from "../redux/GlobalState";
import {IEventData, IGooglePlaceDetails} from "../types/types";
import * as _ from "lodash";
import {MapMarker} from "./MapMarker";
import update from "immutability-helper";
import {Icon, Spinner, Popover, Menu, MenuDivider, MenuItem} from "@blueprintjs/core";
import { SET_SELECTED_CARD } from "../redux/actions/sessionActions";
import { Dispatch } from "redux";
import ReactDOMServer from "react-dom/server";
import {getActiveTags, getBoardSections, getDays} from "../redux/selectors";
import { IDayContent } from "../types/types";

interface ILeg {
    distance: {text: string; value: number};
    duration: {text: string; value: number};
    steps: {polyline: {points: string}}[];
}

interface IState {
    clickedMarkers: string[];
    googleService: { map: any, maps: any } | null;
    directionService: any;
    directionDisplay: any;
    distanceService: any;
    travelLegs: ILeg[];
    polylines: any[]; // Google map polyline object
    movedToSelectedCard: boolean;
    selectedDays: number[];
    selectedTags: Set<string>;
    includeUnassignedDay: boolean;
    filteredPlaces: {[id: string]: IEventData};
}

interface IDispatchProps {
    selectCard(id: string): void;
}
interface IStateProps {
    initialLocation: IGooglePlaceDetails | null;
    places: {[id: string]: IEventData};
    selectedCard: ISelectedCard;
    activeTags: string[];
    activeDays: IDayContent[];
    eventDaysMap: {[id: string]: number};
}
interface IOwnProps {
    isTrip: boolean;
    onClickMapMarker: (eventID: string) => void;
}
type IProps = IStateProps & IDispatchProps & IOwnProps;

class BoardMap extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            clickedMarkers: [],
            googleService: null,
            directionService: null,
            directionDisplay: null,
            distanceService: null,
            travelLegs: [],
            polylines: [],
            movedToSelectedCard: true,
            selectedDays: [],
            selectedTags: new Set(),
            includeUnassignedDay: false,
            filteredPlaces: this.props.places,
        };
    }

    componentWillReceiveProps = (newProps: IProps) => {
        if (newProps.places && Object.keys(newProps.places).length !== Object.keys(this.props.places).length) {
            this.boundMap(newProps.places);
        }
        if (newProps.selectedCard !== this.props.selectedCard) {
            this.setState({movedToSelectedCard: false});
        }
    }

    boundMap = (places: {[id: string]: IEventData}) => {
        if (this.state.googleService && this.state.googleService.map && this.state.googleService.maps) {
            const bounds = new this.state.googleService.maps.LatLngBounds();
            let numWithLocation = 0;
            _.map(places, (place: IEventData) => {
                console.log("all places: ", places);
                console.log("place broken at: ", place);
                if (place.location.lat && place.location.long && this.state.googleService) {
                    bounds.extend(new this.state.googleService.maps.LatLng(place.location.lat, place.location.long));
                    numWithLocation++;
                }
            });
            if (numWithLocation == 0) {
                return;
            }
            if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
                const extendPoint1 = new this.state.googleService.maps.LatLng(bounds.getNorthEast().lat() + 0.005, bounds.getNorthEast().lng() + 0.005);
                const extendPoint2 = new this.state.googleService.maps.LatLng(bounds.getNorthEast().lat() - 0.005, bounds.getNorthEast().lng() - 0.005);
                bounds.extend(extendPoint1);
                bounds.extend(extendPoint2);
            }

            this.state.googleService.map.fitBounds(bounds);
        }
    }

    render() {
        const travelLegs: JSX.Element[] = [];

        if (!this.props.initialLocation) {
            return <div className={"map-area"}><Spinner/></div>
        }

        const tripCenter = this.props.initialLocation && this.props.initialLocation.location
            ? {lat: this.props.initialLocation.location.lat, lng: this.props.initialLocation.location.long}
            : undefined;

        if (this.state.clickedMarkers.length >= 2) {
            this.searchDistance();
        } else {
            this.state.polylines.forEach((polyline) => polyline.setMap(null));
        }

        if (this.props.selectedCard.id && this.props.selectedCard.source === "card" && !this.state.movedToSelectedCard) {
            const singlePlace: {[id: string]: IEventData} = {};
            singlePlace[this.props.selectedCard.id] = this.props.places[this.props.selectedCard.id];
            // this.boundMap(singlePlace);
            this.setState({movedToSelectedCard: true});
        }
        console.log("places to put on Map: ", this.props.places);
        return (
            <div className={"map-area"}>
                <GoogleMapReact bootstrapURLKeys={{ key: "AIzaSyALPi247glKjHskrtaDIwWgnZhfYPr5sIk" }}
                                defaultCenter={tripCenter}
                                defaultZoom={8}
                                yesIWantToUseGoogleMapApiInternals={true}
                                onGoogleApiLoaded={this.onGoogleApiLoaded}
                >
                    {_.map(this.props.places, (place, id: number) => {
                        if (place.location.long && place.location.lat) {
                            return (
                                <MapMarker
                                    key={id}
                                    isSelected={place.id === this.props.selectedCard.id}
                                    lat={place.location.lat}
                                    lng={place.location.long}
                                    onClick={() => this.clickMarker(place.id)}
                                    isClicked={_.includes(this.state.clickedMarkers, place.id)}
                                    event={place}
                                    dayIndex={this.props.eventDaysMap[place.id]}
                                />
                            )
                        }
                    })}
                </GoogleMapReact>
                {travelLegs.length > 0 ? travelLegs : null }
            </div>
        )
    }

    clickMarker = (eventId: string) => {
        this.clearRoutes();
        this.props.onClickMapMarker(eventId);
        this.props.selectCard(eventId);
        if (_.includes(this.state.clickedMarkers, eventId)) {
            const index = _.indexOf(this.state.clickedMarkers, eventId);
            const newList = update(this.state.clickedMarkers, {$splice: [[index, 1]]});
            this.setState({clickedMarkers: newList});
            return;
        }
        const newList = update(this.state.clickedMarkers, {$push: [eventId]});
        this.setState({clickedMarkers: newList});
    }

    onGoogleApiLoaded = (google: { map: any, maps: any }) => {
        const distanceService = new google.maps.DistanceMatrixService();
        const directionService = new google.maps.DirectionsService();

        const directionDisplay = new google.maps.DirectionsRenderer();
        directionDisplay.setMap(google.map);
        this.setState({googleService: google, distanceService, directionService, directionDisplay});
        if (this.props.places && Object.keys(this.props.places).length > 0) {
            this.boundMap(this.props.places);
        }
    }

    searchDistance = () => {
        const google = this.state.googleService;
        if (google) {
            const place1 = this.props.places[this.state.clickedMarkers[0]];
            const place2 = this.props.places[this.state.clickedMarkers[this.state.clickedMarkers.length - 1]];
            const origin = new google.maps.LatLng(place1.location.lat, place1.location.long);
            const destination = new google.maps.LatLng(place2.location.lat, place2.location.long);
            const req = {
                origin: origin,
                destination: destination,
                travelMode: "DRIVING",
                waypoints: this.state.clickedMarkers.slice(1, this.state.clickedMarkers.length-1).map((id: string) => {
                    const place = this.props.places[id];
                    return {
                        location: new google.maps.LatLng(place.location.lat, place.location.long),
                        stopover: true,
                    };
                }),
            };
            this.state.directionService.route(
                req,
                (resp: any, status: any) => this.receiveRoute(resp, status, google),
            )
        }
    }

    clearRoutes = () => {
        if (this.state.directionDisplay) {
            this.state.directionDisplay.setDirections({routes: []});
        }
    }

    receiveRoute = (resp: any, status: any, google: { map: any, maps: any }) => {
        if (status == "OK") {
            if (resp.routes[0].legs.length !== this.state.travelLegs.length || this.state.travelLegs.length === 1) {
                const lines = resp.routes[0].legs.map((leg: ILeg, idx: number) => {
                    const place1 = this.props.places[this.state.clickedMarkers[idx]];
                    const place2 = this.props.places[this.state.clickedMarkers[idx+1]];
                    let currentPath: any[] = []
                    leg.steps.forEach((step) => {
                        currentPath = currentPath.concat(
                            google.maps.geometry.encoding.decodePath(step.polyline.points),
                        );
                    });
                    const line = new google.maps.Polyline({
                        path: currentPath,
                        geodesic: true,
                        strokeOpacity: 0.8,
                        strokeColor: "#5DC2C9",
                        strokeWeight: 5,
                    });
                    const contents = <div>
                        <b>{place1.name}</b> to <b>{place2.name}</b>
                        <br/>
                        <span>
                            <Icon icon={"drive-time"} className={"day-distance-icon"}/>
                            {leg.distance.text}
                        </span>
                        <br/>
                        <span>
                            <Icon icon={"time"} className={"day-distance-icon"}/>
                            {leg.duration.text}
                        </span>
                    </div>
                    const infoWindow = new google.maps.InfoWindow({
                        content: ReactDOMServer.renderToString(contents),
                    });
                    line.setMap(google.map);
                    line.addListener('mouseover', (e: any) => {
                        line.setOptions({strokeWeight: 8});
                        infoWindow.setPosition(e.latLng);
                        infoWindow.open(google.map);
                    });
                    line.addListener('mouseout', () => {
                        line.setOptions({strokeWeight: 5});
                        infoWindow.close();
                    });
                    return line;
                });
                this.state.polylines.forEach((line) => line.setMap(null));
                this.setState({
                    travelLegs: resp.routes[0].legs as ILeg[],
                    polylines: lines,
                });
            }
        }
    }
}

const mapStateToProps = (state: IGlobalState, ownProps: IOwnProps): IStateProps => {
    const eventDaysMap: {[id: string]: number} = {};
    _.forEach(state.currentBoard.eventData, ((event) => {
        eventDaysMap[event.id] = _.findIndex(state.currentTrip.days, (day) => day.events.includes(event.id));
    }));
    console.log("update event map with this: ", eventDaysMap);
    return {
        places: state.currentBoard.eventData,
        selectedCard: state.session.selectedCard,
        initialLocation: ownProps.isTrip ? state.currentTrip.params.tripLocation : state.currentBoard.location,
        activeTags: getBoardSections(state),
        activeDays: getDays(state),
        eventDaysMap,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
    return {
        selectCard: (id: string) => {
            dispatch(SET_SELECTED_CARD({id, source: "map"}));
        },
    };
};

export const ConnectedBoardMap = connect(mapStateToProps, mapDispatchToProps)(BoardMap);