import { combineReducers } from "redux";
import {eventsReducer} from "./eventsReducer";
import * as dayActions from "../actions/dayActions";
import * as paramActions from "../actions/paramsActions";
import * as wishlistActions from "../actions/wishlistActions";
import * as sessionActions from "../actions/sessionActions";
import * as firestoreIdActions from "../actions/firestoreIdActions";
import * as boardActions from "../actions/boardActions";
import * as ownerActions from "../actions/ownerActions";
import {ActionType, getType} from "typesafe-actions";
import {getNextId, initialState, setNextId} from "../GlobalState";
import update from "immutability-helper";
import * as _ from "lodash";
import {IDayContent} from "../../types/types";
import * as eventActions from "../actions/eventActions";

/**************************
 /* FIRESTORE ID REDUCER */
export type FirestoreIdActionsType = ActionType<typeof firestoreIdActions>;

export const firestoreIdReducer = (state = initialState.currentTrip.firestoreId, action: FirestoreIdActionsType) => {
    switch (action.type) {
        case getType(firestoreIdActions.UPDATE_FIRESTORE_ID):
            return action.payload;
        default:
            return state;
    }
}

/************************ 
 /* OWNER REDUCER */
export type OwnerActionsType = ActionType<typeof ownerActions>;

export const ownerReducer = (state = initialState.currentTrip.owner, action: OwnerActionsType) => {
    switch (action.type) {
        case getType(ownerActions.UPDATE_OWNER):
            return action.payload;
        default:
            return state;
    }
}

/************************
/* DAYS REDUCER */
export type DayActionsType = ActionType<typeof dayActions>;

export const daysReducer = (state = initialState.currentTrip.days, action: DayActionsType) => {
    switch (action.type) {
        case getType(dayActions.ADD_DAY):
            return update(state, {$push: [{name: action.payload, description: "", events: []}]});
        case getType(dayActions.SET_DAY_TITLE):
            const editTitleQuery = updateSingleDay(state, action.payload.dayIndex, {name: {$set: action.payload.title}});
            return update(state, editTitleQuery);
        case getType(dayActions.ADD_EVENT_TO_DAY):
            const {eventId, indexDestination} = action.payload;
            if (indexDestination === -1) { // add event to the end of the day
                const addEventQuery = updateSingleDay(state, action.payload.dayIndex, {events: {$push: [eventId]}});
                return update(state, addEventQuery);
            } else { // add event to a specific index in the day array
                const addEventQuery = updateSingleDay(state, action.payload.dayIndex, {events: {$splice: [[indexDestination, 0, eventId]]}});
                return update(state, addEventQuery);
            }
        case getType(dayActions.DELETE_EVENT_FROM_ALL_DAYS):
            // loop through all days and if the event exists, remove it from the list
            const deleteQuery: {[id: number]: {}} = {};
            state.forEach((day, i) => {
                const index = _.indexOf(day.events, action.payload);
                if (index !== -1) {
                    deleteQuery[i] = {events: {$splice: [[index, 1]]}};
                }
            });
            return update(state, deleteQuery);
        case getType(dayActions.REORDER_DAY_EVENT):
            const {fromIdx, toIdx} = action.payload;
            const dayIndex = action.payload.dayIndex;
            const moveQuery: {[id: number]: {}} = {};
            const value = state[dayIndex].events[fromIdx];
            moveQuery[dayIndex] = {events: {$splice: [[fromIdx, 1], [toIdx, 0, value]]}};
            return update(state, moveQuery);
        case getType(dayActions.HYDRATE_DAY_CONTENT):
            return action.payload;
        default:
            return state;
    }
};

const updateSingleDay = (state: IDayContent[], dayIndex: number, daySpecificQuery: {}) => {
    const updateQuery: {[id: number]: {}} = {};
    updateQuery[dayIndex] = daySpecificQuery;
    return updateQuery;
}

/************************
 /* WISHLIST REDUCER */
export type WishlistActionsType = ActionType<typeof wishlistActions>;

export const wishlistReducer = (state = initialState.currentTrip.wishlist, action: WishlistActionsType) => {
    switch (action.type) {
        case getType(wishlistActions.ADD_TO_WISHLIST):
            return update(state, {$push: [action.payload]});
        case getType(wishlistActions.REMOVE_FROM_WISHLIST):
            const index = _.indexOf(state, action.payload);
            if (index !== -1) {
                return update(state, {$splice: [[index, 1]]});
            } else {
                return state;
            }
        case getType(wishlistActions.REORDER_WISHLIST):
            const value = state[action.payload.fromIdx];
            return update(state, {$splice: [[action.payload.fromIdx, 1], [action.payload.toIdx, 0, value]]});
        case getType(wishlistActions.HYDRATE_WISHLIST):
            return action.payload;
        default:
            return state;
    }
};

/************************
 /* Params REDUCER */
export type ParamActionsType = ActionType<typeof paramActions>;

export const paramReducer = (state = initialState.currentTrip.params, action: ParamActionsType) => {
    switch (action.type) {
        case getType(paramActions.SET_NEXT_ID):
            setNextId(action.payload);
            return {...state, nextId: action.payload};
        case getType(paramActions.SET_TRIP_NAME):
            return {...state, tripName: action.payload};
        case getType(paramActions.SET_TRIP_LOCATION):
            return {...state, tripLocation: action.payload};
        case getType(paramActions.HYDRATE_PARAMS):
            return action.payload;
        default:
            return state;
    }
};

/************************
 /* Session REDUCER */
export type SessionActionsType = ActionType<typeof sessionActions>;

export const sessionReducer = (state = initialState.session, action: SessionActionsType) => {
    switch (action.type) {
        case getType(sessionActions.UPDATE_USER):
            return {...state, loggedInUser: action.payload, authLoading: false};
        case getType(sessionActions.SET_IS_TRIP):
            return {...state, isTrip: action.payload};
        case getType(sessionActions.SET_SELECTED_CARD):
            return {...state, selectedCard: action.payload};
        default:
            return state;
    }
};

/************************
 /* Board REDUCER */
export type BoardActionsType = ActionType<typeof boardActions>;

export const boardReducer = (state = initialState.currentBoard, action: BoardActionsType) => {
    switch (action.type) {
        case getType(boardActions.HYDRATE_CURRENT_BOARD):
            return {...action.payload, eventIDs: []};
        case getType(boardActions.ADD_EVENT_ID_TO_BOARD):
            return update(state, {eventIDs: {$push: [action.payload]}});
        case getType(boardActions.ADD_EVENT_DATA_TO_BOARD):
            const id = action.payload.eventID;
            return update(state, {eventData: {[id]: {$set: action.payload.eventData}}});
        case getType(boardActions.SET_BOARD_NAME):
            return {...state, boardName: action.payload};
        case getType(boardActions.DELETE_EVENT_DATA_FROM_BOARD):
            const eventID = action.payload;
            return update(state, {eventData: {$unset: [eventID]}});
        default:
            return state;
    }
};

/************************
 /* ROOT REDUCER */
export default combineReducers({
    currentTrip: combineReducers({
        firestoreId: firestoreIdReducer,
        eventData: eventsReducer,
        wishlist: wishlistReducer,
        params: paramReducer,
        days: daysReducer,
        owner: ownerReducer,
    }),
    currentBoard: boardReducer,
    session: sessionReducer,
});
