import {EVENT_DURATION, IEventData} from "../types/types";
import {EditableText, Icon, Tag} from "@blueprintjs/core";
import * as React from "react";
import {TagList} from "./TagList";
import * as _ from "lodash";
import { eventCategories } from "../redux/GlobalState";

interface IState {
    editedEventData: IEventData| null;
    submitting: boolean;
}
interface IProps {
    eventData: IEventData;
    updateEvent: (event: IEventData) => void;
    onCancel: () => void;
    inList: boolean;
    isEdit?: boolean;
}
export class EditableEvent extends React.Component<IProps, IState> {
    state = {
        editedEventData: this.props.eventData,
        submitting: false,
    };
    render() {
        const locationName = this.state.editedEventData.location.name ? this.state.editedEventData.location.name : this.state.editedEventData.location.googlePlaceDetails ? this.state.editedEventData.location.googlePlaceDetails.address : "";
        return (
            <div className={"edit-event-dialog"}>
                <div className={"edit-event-top"}>
                    <EditableText placeholder={"Enter Title"} className={"edit-event-title"} value={this.state.editedEventData.name} onChange={this.updateName}/>
                    <Tag round interactive className={"edit-event-remove"} onClick={this.props.onCancel}> {this.props.isEdit ? "cancel" : "remove"} </Tag>

                </div>
                <div className={"edit-event-middle"}>
                    <div className={"edit-event-image-wrapper"}>
                        <img className={"edit-event-image"} src={this.state.editedEventData.image}/>
                        <div className={"edit-event-location"}>
                            <Icon iconSize={14} icon={"map-marker"} className={"event-loc-pin"}/>
                            <div className={"event-loc-name"}>{locationName}</div>
                        </div>                    </div>
                    <div className={"edit-event-details"}>
                        <EditableText placeholder={"Enter description"} minLines={10} multiline={true} value={this.state.editedEventData.description} onChange={this.updateDescription}/>
                        <div className={"edit-event-tag-row"}>
                            {/*<object type="image/svg+xml" data="icons/time-icon.svg" className="event-icon" />*/}
                            <Tag className={"tag time-tag"} interactive round active={this.state.editedEventData.duration === EVENT_DURATION.SHORT} onClick={() => this.updateDuration(EVENT_DURATION.SHORT)}>Short</Tag>
                            <Tag className={"tag time-tag"} interactive round active={this.state.editedEventData.duration === EVENT_DURATION.HALF_DAY} onClick={() => this.updateDuration(EVENT_DURATION.HALF_DAY)}>Half Day</Tag>
                            <Tag className={"tag time-tag"} interactive round active={this.state.editedEventData.duration === EVENT_DURATION.FULL_DAY} onClick={() => this.updateDuration(EVENT_DURATION.FULL_DAY)}>Full Day</Tag>
                        </div>
                        <div className={"edit-event-tag-row"}>
                            {/*<object type="image/svg+xml" data="icons/globe.svg" className="event-icon" />*/}
                            <TagList interactive={true} selected={this.state.editedEventData.tags} tags={eventCategories} updateSelectedTags={this.updateTags}/>
                        </div>
                    </div>
                </div>
                <div className={"edit-event-center"}>
                    <Tag round interactive className={"edit-event-add"} onClick={this.addEvent}> {this.props.isEdit ? "Edit" : "Add"} </Tag>
                    {/*<div> to </div>*/}
                    {/*<div> Wishlist </div>*/}
                </div>
            </div>
        );
    }

    updateName = (value: string) => {
        if (this.state.editedEventData) {
            const newState = _.cloneDeep(this.state.editedEventData);
            newState.name = value;
            this.setState({editedEventData: newState});
        }
    }
    updateDescription = (value: string) => {
        if (this.state.editedEventData) {
            const newState = _.cloneDeep(this.state.editedEventData);
            newState.description = value;
            this.setState({editedEventData: newState});
        }
    }
    updateDuration = (value: EVENT_DURATION) => {
        if (this.state.editedEventData) {
            const newState = _.cloneDeep(this.state.editedEventData);
            newState.duration = value;
            this.setState({editedEventData: newState});
        }
    }
    updateTags = (newTags: string[]) => {
        if (this.state.editedEventData) {
            const newState = _.cloneDeep(this.state.editedEventData);
            newState.tags = newTags;
            this.setState({editedEventData: newState});
        }
    }
    addEvent = () => {
        this.props.updateEvent(this.state.editedEventData);
        if (this.props.inList) {
            this.props.onCancel();
        }
    }

    escapeKey = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.keyCode === 27) { // ESC
            this.props.onCancel();
        }
    }
}