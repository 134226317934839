import {areLocationsEqual, IGooglePlaceSuggestion, locationSelectProps} from "../util/locations";
import {Button, Dialog, Icon, Intent, MenuItem} from "@blueprintjs/core";
import * as React from "react";
import {firestore} from "../util/firestore";
import {getPlaceDetailsFromPlaceId, suggestPlaceFromQuery} from "../api/server";
import {Suggest} from "@blueprintjs/select";
import {ConnectedUserAccount} from "./UserAccount";
import {IGooglePlaceDetails} from "../types/types";
import {SET_TRIP_LOCATION} from "../redux/actions/paramsActions";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import * as _ from "lodash";
import {Redirect} from "react-router";

const LocationSuggest = Suggest.ofType<IGooglePlaceSuggestion>();

interface IDispatchProps {
    setTripLocation: (tripLocation: IGooglePlaceDetails) => void;
}
interface IState {
    location: IGooglePlaceDetails | null;
    goToTrip: string;
    loading: boolean;
    validLocations: IGooglePlaceSuggestion[];
    locationQuery: string;
    moveToApp: boolean;
    boardID: string;
}
class LandingNavbar extends React.Component<IDispatchProps, IState> {
    constructor(props: IDispatchProps) {
        super(props);
        this.state = {
            location: null,
            goToTrip: "",
            loading: false,
            validLocations: [],
            locationQuery: "",
            moveToApp: false,
            boardID: "",
        }
    }
    render() {
        if (!_.isEmpty(this.state.boardID)) {
            return (<Redirect to={"/board/" +this.state.boardID}/>);
        } else if (!_.isEmpty(this.state.goToTrip)) {
            return (<Redirect to={"/trip/" +this.state.goToTrip}/>);
        } else {
            return this.renderNavBar()
        }
    }
    renderNavBar() {
        return (
        <div className={"landing-header"}>
            <div className={"landing-info"}>
                <div className={"landing-title"}>
                    <img width={100} src={"/images/logo.png"}/>
                </div>
            </div>
            <div className={"landing-location"}>
                <div className={"inspiration-link"}>
                    <LocationSuggest
                        {...locationSelectProps}
                        inputValueRenderer={this.renderInputValue}
                        itemsEqual={areLocationsEqual}
                        // we may customize the default filmSelectProps.items by
                        // adding newly created items to the list, so pass our own.
                        items={this.state.validLocations}
                        noResults={this.noResultRender()}
                        onItemSelect={this.handleValueChange}
                        popoverProps={{minimal: true, fill: true}}
                        query={this.state.locationQuery}
                        onQueryChange={this.onQueryChange}
                        className={"dropdown-options"}
                        inputProps={{
                            leftIcon: "search",
                            intent: Intent.PRIMARY,
                            placeholder: "Where do you want to go?",
                            className: "landing-location-input"
                        }}
                    />
                </div>
                {this.getPopup()}
            </div>
            <div>
                <ConnectedUserAccount/>
            </div>
        </div>);
    }

    newTrip = () => {
        // create new trip with this location
        console.log("create a new trip to: ", this.state.location);
        if (this.state.location) {
            this.setState({loading: true});
            this.props.setTripLocation(this.state.location);
            firestore.newTrip(this.state.location)
                .then((newTrip) => {
                    this.setState({goToTrip: newTrip.firestoreId});
                });
        }
    }

    newBoard = () => {
        // create new trip with this location
        console.log("create a new board to: ", this.state.location);
        if (this.state.location) {
            this.setState({loading: true});
            this.props.setTripLocation(this.state.location);
            firestore.newBoard(this.state.location)
                .then((newBoard) => {
                    this.setState({boardID: newBoard.firestoreId});
                });
        }
    }


    private renderInputValue = (loc: IGooglePlaceSuggestion) => loc.description;

    private handleValueChange = (loc: IGooglePlaceSuggestion) => {
        console.log("VALUE CHANGE TO: ", loc);
        this.setState({locationQuery: loc.description});
        getPlaceDetailsFromPlaceId(loc.place_id)
            .then((placeDetails) => {
                this.setState({location: placeDetails});
            });
    };

    getPopup() {
        return (
        <Dialog
            isOpen={!_.isEmpty(this.state.location)}
            title={"What type of document would you like to create"}
            icon={"airplane"}
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            enforceFocus={true}
            style={{width: "500px", lineHeight: "18px"}}
            onClose={() => this.setState({location: null, locationQuery: "", validLocations: []})}
        >
            <div className={"choose-type"}>
                 <div className={"location-name"}> {this.state.location ? this.state.location.name : ""} </div>
                 <div className={"options"}>
                     <div className={"section"}>
                         <img src={"images/Map_light.svg"}/>
                         Create a Board if you are just trying to collect recommendations on a specific place but you
                         are not yet ready to plan a trip there. A Board will hold your recommendations and
                         when you want to go on a trip, you can convert your Board to a Trip with one click.
                         <Button className={"go-btn"} intent={Intent.PRIMARY} disabled={_.isEmpty(this.state.location)} onClick={this.newBoard}> Board </Button>

                     </div>
                     <div className={"vl"} />
                     <div className={"section"}>
                         <img src={"images/adventure.svg"}/>
                         Trips are more geared towards helping you plan your next trip. Trips let you organize your
                         recommendations to a day by day plan. Choose this option if you have an idea around a trip coming up
                         and want to start planning that trip.
                         <Button className={"go-btn"} intent={Intent.PRIMARY} disabled={_.isEmpty(this.state.location)} onClick={this.newTrip}> Trip </Button>

                     </div>
                 </div>
            </div>
        </Dialog>
        );
    }

    noResultRender() {
        if (this.state.locationQuery.length > 3) {
            return (<MenuItem className={"location-name-entry"} disabled={true} text="This place sounds awesome, but we don't recognize it" />);
        } else {
            return null;
        }
    }

    onQueryChange = (query: string) => {
        console.log("new query", query);
        this.setState({locationQuery: query});
        if (query.length > 2) {
            suggestPlaceFromQuery(query)
                .then((suggestions) => {
                    this.setState({validLocations: suggestions})
                });
        }
    }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
    return {
        setTripLocation: (tripLocation: IGooglePlaceDetails) => {
            dispatch(SET_TRIP_LOCATION(tripLocation));
        }
    };
};

export const ConnectedLandingNavbar = connect(null, mapDispatchToProps)(LandingNavbar);