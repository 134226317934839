import * as React from "react";
import {
    Button,
    ButtonGroup,
    Card,
    Collapse,
    H2,
    H3,
    H4,
    Icon,
    InputGroup,
    Intent,
    MenuItem,
    Spinner,
    Tag
} from "@blueprintjs/core";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import * as _ from "lodash";
import {
    getPlaceDetailsFromPlaceId,
    getPublicBoards,
    getPublicTrips,
    IPublicTrip,
    suggestPlaceFromQuery
} from "../api/server";
import {Suggest} from "@blueprintjs/select";
import {areLocationsEqual, IGooglePlaceSuggestion, locationSelectProps} from "../util/locations";
import {IGooglePlaceDetails} from "../types/types";
import {SET_TRIP_LOCATION} from "../redux/actions/paramsActions";
// @ts-ignore
import * as ReactRotatingText from "react-rotating-text";
import {ConnectedUserAccount} from "../components/UserAccount";
import {firestore} from "../util/firestore";
import {IBoard, IBookDate, IBookHost, IBookPage, ITrip} from "../redux/GlobalState";
import {ConnectedBoardEvent} from "../components/BoardEvent";
// @ts-ignore
import Carousel, { Modal, ModalGateway } from "react-images";
import Gallery, {PhotoProps} from "react-photo-gallery";
import {fetchTripEvents, hydrateCurrentTrip} from "../redux/actions/batchedActions";
import {ConnectedDayCard} from "../components/DayCard";
import {DragDropContext, DropResult} from "react-beautiful-dnd";
import {InputText} from "../components/InputText";
import {ReservationForm} from "../components/ReservationForm";

interface IDispatchProps {
    setTripLocation: (tripLocation: IGooglePlaceDetails) => void;
    hydrateCurrentTrip: (trip: ITrip) => void;
    fetchTripEvents: (tripID: string) => void;
}

interface IState {
    bookPage: IBookPage | null;
    tripData: ITrip | null;
    viewerIsOpen: boolean;
    currentImage: number;
    selectedDate: number | null;
}

class BookScreen extends React.Component<IDispatchProps, IState> {
    constructor(props: IDispatchProps) {
        super(props);
        this.state = {
            bookPage: null,
            tripData: null,
            viewerIsOpen: false,
            currentImage: 0,
            selectedDate: null,
        }
    }

    async componentDidMount() {
        const docId: string = window.location.pathname.split('/')[2];
        const loadResp = await firestore.loadBookPage(docId);
        console.log("doc id: ", docId);
        console.log("response is: ", loadResp);
        if (loadResp.bookPage) {
            this.setState({bookPage: loadResp.bookPage});
            const tripResp = await firestore.loadTrip(loadResp.bookPage.tripID);
            console.log("trip data: ", tripResp);
            if (tripResp.trip) {
                this.props.hydrateCurrentTrip(tripResp.trip);
                this.props.fetchTripEvents(tripResp.trip.firestoreId);
                this.setState({tripData: tripResp.trip});
            }

        } else {
            console.log(loadResp.err);
        }
    }

    render() {
        if (!this.state.bookPage) {
            return <div style={{position: "fixed", left: "50%", top: "50%"}}>
                <Spinner size={Spinner.SIZE_LARGE}/>
            </div>
        }
        return (
            <div className={"book"}>
                <div className={"landing-header"}>
                    <div className={"landing-info"}>
                        <div className={"landing-title"}>
                            <img width={100} src={"/images/logo.png"} />
                        </div>
                    </div>
                    <div className={"company"}>
                        <img className={"company-logo"} src={"https://static.wixstatic.com/media/fb2fb1_fbb7189978834aaf9fbc89d340f7fbaa~mv2_d_1563_1562_s_2.png/v1/fill/w_250,h_250,al_c,q_80,usm_0.66_1.00_0.01/TransparentLogo.webp"} />
                        <div className={"company-name"}>Travel World Culture</div>
                    </div>
                    <div>
                        <ConnectedUserAccount />
                    </div>
                </div>
                <div className={"book-trip"}>
                    <div className={"trip-content"}>
                        <div className={"flex"}>
                            <div className={"narrow"}>
                                <div className={"subtitle top"}> <H2> {this.state.bookPage.subtitle} </H2></div>
                            </div>
                        </div>
                        {this.renderImages(this.state.bookPage.images)}
                        <div className={"flex"}>
                            <div className={"narrow"}>
                                <div className={"subtitle"}> <H2> {this.state.bookPage.subtitle} </H2></div>
                                <div className={"summary"}>
                                    <div className={"item"}>
                                        <span className={"item-icon"}><Icon iconSize={26} icon={"time"}/> duration </span>
                                        <div> {this.state.bookPage.duration} </div>
                                    </div>
                                    <div className={"item"}>
                                        <span className={"item-icon"}><Icon iconSize={26} icon={"globe"}/> cities </span>
                                        <div className={"cities"}> {this.state.bookPage.cities.map((city, i) => <span key={i}> {city} </span>)} </div>
                                    </div>
                                    <div className={"item"}>
                                        <span className={"item-icon"}><Icon iconSize={26} icon={"dollar"}/> value </span>
                                        <div> {this.state.bookPage.cost} </div>
                                    </div>
                                </div>
                                {this.bookNow(this.state.bookPage.dates[0])}
                                {this.renderTextSection("Why you'd want to come", this.state.bookPage.pitch)}
                                <H2> So what's the plan </H2>
                            </div>
                            <div className={"plan"}>
                                {this.renderPlan(this.state.tripData)}
                            </div>
                            <div className={"flex narrow text-section"}>
                                <H2> What are you waiting for, join us! </H2>
                                {/*{this.renderDates(this.state.bookPage.dates)}*/}
                                <ReservationForm hide={false} />
                                {this.bookNow(this.state.bookPage.dates[0])}
                            </div>
                            <div className={"flex narrow text-section"}>
                                <div className={"know"}>
                                    <H2> Get to know your organizers </H2>
                                    {this.renderHosts(this.state.bookPage.hosts)}
                                    <div className={"text-section"}>
                                        {this.state.bookPage.host}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    bookNow = (date: IBookDate) => {
        return (
            <div className={"center"}>
                <div className={"cta-section"}>
                    <div className={"single-date"}> {date.dateName} </div>
                    <div className={"book-now button"}>
                        Reserve your spot for only $10
                    </div>
                </div>
            </div>
        );
    }

    renderImages = (images: string[]) => {
        const photos = images.map((im) => {
            return {
                src: im,
                width: 3,
                height: 4,
            }
        });
        return (
            <div className={"trip-photos"}>
                <Gallery photos={photos} onClick={this.openLightBox} />
                <ModalGateway>
                    {this.state.viewerIsOpen ? (
                        <Modal onClose={this.closeLightBox}>
                            <Carousel
                                currentIndex={this.state.currentImage}
                                views={photos.map(x => ({
                                    ...x,
                                    caption: "title",
                                }))}
                            />
                        </Modal>
                    ) : null}
                </ModalGateway>
            </div>
        );
    }

    renderPlan = (trip: ITrip | null) => {
        if (!trip) {
            return (
                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Spinner size={Spinner.SIZE_STANDARD}/>
                </div>
            );
        } else {

            return (
                <DragDropContext
                    onDragEnd={(result: DropResult) => console.log("dropped ", result)}
                >
                    <div className={"plan-days"}>
                        <div className={"days-screen"}>
                            {trip.days.map((day, i) => <ConnectedDayCard
                                disableDnd={true} className={"days-column"} key={i} dayIndex={i} dayContent={day} droppableID={"NONE"}/>)}
                        </div>
                    </div>
                </DragDropContext>
            )
        }
    }

    renderHosts = (hosts: IBookHost[]) => {
        return (
            <div className={"organizers"}>
                {hosts.map((host, i) => <div key={i} className={"host"}>
                    <img className={"picture"} src={host.image} />
                    <div className={"name"}> {host.name} </div>
                    <div className={"bottom-right"}>
                        <Tag intent={Intent.SUCCESS} icon={"tick"} large minimal className={"verified"}> Verified </Tag>
                        <a href={host.instagram} target={"_blank"} className="instagram-logo"></a>
                    </div>
                </div>)}
            </div>
        );
    }

    renderDates = (dates: IBookDate[]) => {

        const list =  dates.map((date, i) => {
            const isHighlighted = this.state.selectedDate === i;
        return (
        <div key={i} className={isHighlighted ? "date-option date-option-active" : "date-option"} onClick={() => this.setState({selectedDate: i})}>
            <span key={0}>{date.dateName}</span>
            <span key={1}>{date.spots}</span>
            <span key={2}>${date.price}</span>
        </div>
        )}
        );
        return (
            <div className={"dates"}>
                <div className={"title"}>
                    <span key={0}>Date</span>
                    <span key={1}>Spots</span>
                    <span key={2}>Price</span>
                </div>
                {list}
                <div className={"date-option"}>
                    <span key={0}>Custom Trip</span>
                    <span key={1}>private</span>
                    <span key={2}>$</span>
                </div>
            </div>
        )
    }

    openLightBox = (event: React.MouseEvent, photos: {
        index: number
        next: PhotoProps<{}> | null
        photo: PhotoProps<{}>
        previous: PhotoProps<{}> | null
    },) => {
        console.log("clikced pgoto: ", photos);
        this.setState({viewerIsOpen: true, currentImage: photos.index});
    }

    closeLightBox = () => {
        this.setState({viewerIsOpen: false});
    }

    renderTextSection = (title: string, content: string) => {
        return (
            <div className={"text-section"}>
                <H2>{title}</H2>
                <div>
                    {content}
                </div>
            </div>
        );
    }


}

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
    return {
        setTripLocation: (tripLocation: IGooglePlaceDetails) => {
            dispatch(SET_TRIP_LOCATION(tripLocation));
        },
        hydrateCurrentTrip: (trip: ITrip) => {
            dispatch(hydrateCurrentTrip(trip));
        },
        fetchTripEvents: (tripID: string) => {
            dispatch(fetchTripEvents(tripID));
        },
    };
};

export const ConnectedBookScreen = connect(null, mapDispatchToProps)(BookScreen);