import {batchActions} from "redux-batched-actions";
import {ADD_EVENT, HYDRATE_EVENTS} from "./eventActions";
import {IEventData, IDayContent} from "../../types/types";
import {ADD_TO_WISHLIST, REMOVE_FROM_WISHLIST, HYDRATE_WISHLIST} from "./wishlistActions";
import {ADD_EVENT_TO_DAY, DELETE_EVENT_FROM_ALL_DAYS, HYDRATE_DAY_CONTENT} from "./dayActions";
import {UPDATE_FIRESTORE_ID} from "./firestoreIdActions";
import {HYDRATE_PARAMS} from "./paramsActions";
import {UPDATE_OWNER} from "./ownerActions";

import { IStateParams, ITrip } from "../GlobalState";
import {firestore} from "../../util/firestore";
import {Dispatch} from "redux";
import {ADD_EVENT_DATA_TO_BOARD, ADD_EVENT_ID_TO_BOARD, DELETE_EVENT_DATA_FROM_BOARD} from "./boardActions";

export const newEventToWishlist = (event: IEventData) => batchActions([ADD_EVENT(event), ADD_TO_WISHLIST(event.id)], "newEventToWishlist");

export const moveEventToDay = (eventID: string, dayIndex: number, indexDestination: number) => batchActions([REMOVE_FROM_WISHLIST(eventID), DELETE_EVENT_FROM_ALL_DAYS(eventID), ADD_EVENT_TO_DAY(eventID, dayIndex, indexDestination)], "moveEventToDay");

export const moveEventFromDayToWishlist = (eventID: string) => batchActions([DELETE_EVENT_FROM_ALL_DAYS(eventID), ADD_TO_WISHLIST(eventID)], "moveEventFromDayToWishlist");

export const addEventToTrip = (tripID: string, newEvent: IEventData) => async (dispatch: Dispatch) => {
    console.log("dispatching adding this event to trip... ", {newEvent, tripID});
    const firebaseEvent = await firestore.events.add(newEvent);
    return firestore.trips.doc(tripID).collection("events").doc(firebaseEvent.id).set({});
};

export const updateEventInFirestore = (eventID: string, newEvent: IEventData) => async (dispatch: Dispatch) => {
    await firestore.events.doc(eventID).set(newEvent);
    newEvent.id = eventID;
    dispatch(ADD_EVENT_DATA_TO_BOARD(eventID, newEvent));
};

export const hydrateCurrentTrip = (newTrip: ITrip) => batchActions([
  UPDATE_FIRESTORE_ID(newTrip.firestoreId),
  HYDRATE_EVENTS(newTrip.eventData),
  // HYDRATE_WISHLIST(newTrip.wishlist),
  HYDRATE_PARAMS(newTrip.params),
  HYDRATE_DAY_CONTENT(newTrip.days),
  UPDATE_OWNER(newTrip.owner),
], "hydrateCurrentTrip");

export const fetchTripEvents = (tripID: string) => async (dispatch: Dispatch) => {
    const boardRef = firestore.trips.doc(tripID).collection("events");
    boardRef.onSnapshot( snapshot => {
        console.log("events changes with value...: ", snapshot);
        const source = snapshot.metadata.hasPendingWrites ? "Local" : "Server";
        console.log(source, " docs: ", snapshot.docs);
        snapshot.docChanges().forEach((change) => {
            console.log("trip change is: ", change);
            const eventID = change.doc.id;
            if (change.type === "added") {
                firestore.events.doc(eventID).get()
                    .then((doc) => {
                        const event = doc.data() as IEventData;
                        event.id = eventID;
                        dispatch(ADD_EVENT_DATA_TO_BOARD(eventID, event));
                    });
                dispatch(ADD_TO_WISHLIST(eventID));
            }
        });
    });
};

export const deleteEvent = (tripID: string, eventID: string) => async (dispatch: Dispatch) => {
    await firestore.trips.doc(tripID).collection("events").doc(eventID).delete();
    dispatch(DELETE_EVENT_DATA_FROM_BOARD(eventID));
    dispatch(DELETE_EVENT_FROM_ALL_DAYS(eventID));
    dispatch(REMOVE_FROM_WISHLIST(eventID));
};
