import * as React from "react"
import {EditableText, H3, Icon, Tag} from "@blueprintjs/core";
import {ConnectedBoardMap} from "../components/BoardMap";
import {ConnectedEventDetails} from "../components/EventDetails";
import {fetchEvents, HYDRATE_CURRENT_BOARD} from "../redux/actions/boardActions";
import {connect} from "react-redux";
import {eventsByTag, eventsUnassignedDay, getBoardSections} from "../redux/selectors";
import {emptyBoardData, IBoard, IGlobalState} from "../redux/GlobalState";
import * as _ from"lodash";
import {ToggleButton} from "../components/ToggleButton";
import {IDayContent} from "../types/types";
import {ConnectedNewTripEvent} from "../components/NewTripEvent";
import {ADD_DAY} from "../redux/actions/dayActions";
import {SET_TRIP_NAME} from "../redux/actions/paramsActions";

interface IState {
    boardNotFound: boolean;
    isHydrated: boolean;
    board: IBoard;
    selectedEvent: string;
    boardName: string;
    orderBy: string;
    mobileShowMap: boolean | null;
}
interface IOwnProps {
}
interface IStateProps {
    wishlistLength: number;
    eventIDs: string[];
    activeTags: string[];
    tagToEvents: {[tag: string]: string[]};
    days: IDayContent[];
    eventsUnassignedDays: string[];
    tripName: string;
}
interface IDispatchProps {
    hydrateCurrentBoard: (board: IBoard) => void;
    fetchEvents: (boardID: string) => void;
    addNewDay: (name: string) => void;
    setTripName: (name: string) => void;
}
type IProps = IDispatchProps & IStateProps & IOwnProps;


export class PlannerV2ScreenComp extends React.Component<IProps, IState> {

    state = {
        boardNotFound: false,
        isHydrated: false,
        board: emptyBoardData(),
        selectedEvent: "",
        boardName: "",
        orderBy: "Category",
        mobileShowMap: null,
    };

    render() {
        console.log('tag events map: ', this.props.tagToEvents);
        let events: any = [];
        if (this.state.orderBy === "Category") {
            events = _.map(this.props.tagToEvents, (eventIDs, tag) => {
                console.log("events rendering with: ", {tag ,eventIDs});
                return (
                    <div className={"events-section"}  key={tag}>
                        <div className={"event-section-name"}>
                            <H3> {tag} </H3>
                        </div>
                        <div className={"events"}>
                            {eventIDs.map((id, i) => <ConnectedNewTripEvent tagType={"day"} key={id} eventID={id} onClickEvent={() => this.onEventClick(id)}/>)}
                        </div>
                    </div>
                );
            });
        } else if (this.state.orderBy === "Days") {
            if (!_.isEmpty(this.props.eventsUnassignedDays)) {
                events.push(<div className={"events-section"} key={"unassigned"}>
                    <div className={"event-section-name"}>
                        <H3> Unassigned </H3>
                    </div>
                    <div className={"events"}>
                        {this.props.eventsUnassignedDays.map((id, i) => <ConnectedNewTripEvent tagType={"category"} key={id} eventID={id} onClickEvent={() => this.onEventClick(id)}/>)}
                    </div>
                </div>)
            }
            _.map(this.props.days, (day, i) => {
                console.log("events rendering with day: ", day);
                events.push(
                    <div className={"events-section"}  key={i}>
                        <div className={"event-section-name"}>
                            <H3> {day.name} </H3>
                        </div>
                        <div className={"events"}>
                            {day.events.map((id, i) => <ConnectedNewTripEvent tagType={"category"} key={id} eventID={id} onClickEvent={() => this.onEventClick(id)}/>)}
                            {day.events.length === 0 ? <div className={"empty-day"}> <Icon icon={"heat-grid"} iconSize={40}/> No Events Yet </div> : null}
                        </div>
                    </div>
                );
            });
        }
        return (
            <div className={"main-screen"}>
                <div className={"main-board"}>
                    <div className={this.state.mobileShowMap ? "events-panel hide" : "events-panel"}>
                        <div className={"trip-header"}>
                            <div className={"title"}>
                                <EditableText className={"title"} value={this.props.tripName} placeholder={"Enter Trip Name"} onChange={this.props.setTripName}/>
                            </div>
                            <div>
                                <ToggleButton inputs={["Category", "Days"]} initialSelection={"Category"} onChange={this.onChangeOrder}/>
                            </div>
                        </div>
                        <div className={"events-wrapper"}>
                            {events}
                            {this.state.orderBy === "Days" ? <div className={"trip-add-day"} onClick={this.addDay}> <Icon icon={"plus"} iconSize={40} /> New Day</div>
                                : null}
                        </div>
                    </div>
                    <div className={(this.state.mobileShowMap == true || this.state.mobileShowMap === null)? "board-map" : "board-map hide"}>
                        <ConnectedBoardMap onClickMapMarker={this.onEventClick} isTrip={true} />
                        <div className={"map-overlay"}>
                            {!_.isEmpty(this.state.selectedEvent) ? <ConnectedEventDetails unselectEvent={this.onUnselectEvent} selectedEventID={this.state.selectedEvent} /> : null}
                        </div>
                    </div>
                    <div className={this.state.mobileShowMap ? "show-map selected" : "show-map"} onClick={() => this.setState({mobileShowMap: !this.state.mobileShowMap})}>
                        <Icon icon={"map"} iconSize={20} color={"#898B8B"}/>
                        <span> Map </span>
                    </div>
                </div>
            </div>
        )
    }
    onEventClick = (eventID: string) => {
        this.setState({selectedEvent: eventID});
    }
    onUnselectEvent = () => {
        this.setState({selectedEvent: ""});
    }

    onChangeOrder = (newOrder: string) => {
        this.setState({orderBy: newOrder});
    }

    addDay = () => {
        const dayCounter = this.props.days.length + 1;
        this.props.addNewDay("Day " + dayCounter.toString());
    }
}


const mapDispatchToProps = (dispatch: any): IDispatchProps => { // TODO: fix dispatch type
    return {
        hydrateCurrentBoard: (board: IBoard) => {
            console.log("hydratung boiard... nothing");
            dispatch(HYDRATE_CURRENT_BOARD(board));
        },
        fetchEvents: (boardID: string) => {
            dispatch(fetchEvents(boardID));
        },
        addNewDay: (name: string) => {
            dispatch(ADD_DAY(name));
        },
        setTripName: (name: string) => {
            dispatch(SET_TRIP_NAME(name));
        },
    };
};
const mapStateToProps = (state: IGlobalState): IStateProps => {
    return {
        wishlistLength: state.currentTrip.wishlist.length,
        eventIDs: state.currentTrip.wishlist,
        activeTags: getBoardSections(state),
        tagToEvents: eventsByTag(state),
        days: state.currentTrip.days,
        eventsUnassignedDays: eventsUnassignedDay(state),
        tripName: state.currentTrip.params.tripName,
    };
};

export const PlannerScreenV2 =  connect(mapStateToProps, mapDispatchToProps)(PlannerV2ScreenComp);