import * as React from "react";
import {IDayContent} from "../types/types";
import {Button, EditableText, H2, Icon, Tag} from "@blueprintjs/core";
import {connect} from "react-redux";
import {IGlobalState} from "../redux/GlobalState";
import * as _ from "lodash";
import {ConnectedDayCard} from "../components/DayCard";
import {Dispatch} from "redux";
import {ADD_DAY} from "../redux/actions/dayActions";
import {DayActionsType} from "../redux/reducers";
import {eventsUnassignedDay} from "../redux/selectors";
import {SET_TRIP_NAME} from "../redux/actions/paramsActions";

interface IOwnProps {
}
interface IDispatchProps {
    addNewDay: (name: string) => void;
    setTripName: (name: string) => void;
}
interface IStateProps {
    days: IDayContent[];
    unassignedEvents: string[];
    tripName: string;
}
type IProps = IOwnProps & IStateProps & IDispatchProps;
class DaysScreen extends React.Component<IProps, never> {


    render() {
        const UnassignedDay: IDayContent = {
            name: "Unassigned",
            description: "Events that aren't in any days",
            events: this.props.unassignedEvents,
        };
        return (
            <div className={"day-plan"}>
                <div className={"title"}>
                    <EditableText className={"title"} value={this.props.tripName} placeholder={"Enter Trip Name"} onChange={this.props.setTripName}/>
                </div>
                <div className={"days-screen"}>
                    <ConnectedDayCard disableDnd={false} className={"days-column"} key={"unassigned"} dayContent={UnassignedDay} dayIndex={-1} droppableID={"unassigned"}/>
                    {this.props.days.map((day, i) => <ConnectedDayCard disableDnd={false} className={"days-column"} key={i} dayContent={day} dayIndex={i} droppableID={"dayScrn-" + i}/>)}
                    <div className={"new-day day-card-header"}>
                        <Tag round interactive className={"add-day-btn"} onClick={this.addDay} rightIcon={"plus"}> New Day</Tag>
                    </div>
                </div>
            </div>
        )
    }

    addDay = () => {
        const dayCounter = this.props.days.length + 1;
        this.props.addNewDay("Day " + dayCounter.toString());
    }
}

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
    return {
        addNewDay: (name: string) => {
            dispatch(ADD_DAY(name));
        },
        setTripName: (name: string) => {
            dispatch(SET_TRIP_NAME(name));
        },
    };
};
const mapStateToProps = (state: IGlobalState): IStateProps => {
    return {
        days: state.currentTrip.days,
        unassignedEvents: eventsUnassignedDay(state),
        tripName: state.currentTrip.params.tripName,

    };
};

export const ConnectedDaysScreen = connect(mapStateToProps, mapDispatchToProps)(DaysScreen);