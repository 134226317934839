import * as React from "react";
import {Button, ButtonGroup, Card, Collapse, H2, H3, H4, Icon, Intent, MenuItem, Spinner} from "@blueprintjs/core";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import * as _ from "lodash";
import {
    getPlaceDetailsFromPlaceId,
    getPublicBoards,
    getPublicTrips,
    IPublicTrip,
    suggestPlaceFromQuery
} from "../api/server";
import {Suggest} from "@blueprintjs/select";
import {areLocationsEqual, IGooglePlaceSuggestion, locationSelectProps} from "../util/locations";
import {IGooglePlaceDetails} from "../types/types";
import {SET_TRIP_LOCATION} from "../redux/actions/paramsActions";
// @ts-ignore
import * as ReactRotatingText from "react-rotating-text";
import {ConnectedUserAccount} from "../components/UserAccount";
import {firestore} from "../util/firestore";
import {IBoard, IBookDate, IBookHost, IBookPage, IHost, IHostPerson, ITrip} from "../redux/GlobalState";
import {ConnectedBoardEvent} from "../components/BoardEvent";
// @ts-ignore
import Carousel, { Modal, ModalGateway } from "react-images";
import Gallery, {PhotoProps} from "react-photo-gallery";
import {hydrateCurrentTrip} from "../redux/actions/batchedActions";
import {ConnectedDayCard} from "../components/DayCard";
import {DragDropContext, DropResult} from "react-beautiful-dnd";
import YouTube from "react-youtube";
import {LocationThumbnail} from "../components/LocationThumbnail";

interface IDispatchProps {
    setTripLocation: (tripLocation: IGooglePlaceDetails) => void;
    hydrateCurrentTrip: (trip: ITrip) => void;
}

interface IState {
    hostData: IHost | null;
    // bookPage: IBookPage | null;
    tripData: ITrip | null;
    viewerIsOpen: boolean;
    currentImage: number;
}

class HostScreen extends React.Component<IDispatchProps, IState> {
    constructor(props: IDispatchProps) {
        super(props);
        this.state = {
            hostData: null,
            // bookPage: null,
            tripData: null,
            viewerIsOpen: false,
            currentImage: 0,
        }
    }

    async componentDidMount() {
        const docId: string = window.location.pathname.split('/')[2];
        const loadResp = await firestore.loadHostPage(docId);
        console.log("doc id: ", docId);
        console.log("response is: ", loadResp);
        if (loadResp.hostData) {
            this.setState({hostData: loadResp.hostData});
        } else {
            console.log(loadResp.err);
        }
    }

    render() {
        if (!this.state.hostData) {
            return <div style={{position: "fixed", left: "50%", top: "50%"}}>
                <Spinner size={Spinner.SIZE_LARGE}/>
            </div>
        }
        return (
            <div className={"book"}>
                <div className={"landing-header"}>
                    <div className={"landing-info"}>
                        <div className={"landing-title"}>
                            <img width={100} src={"/images/logo.png"} />
                        </div>
                    </div>
                    <div className={"company"}>
                        <img className={"company-logo"} src={"https://static.wixstatic.com/media/fb2fb1_fbb7189978834aaf9fbc89d340f7fbaa~mv2_d_1563_1562_s_2.png/v1/fill/w_250,h_250,al_c,q_80,usm_0.66_1.00_0.01/TransparentLogo.webp"} />
                        <div className={"company-name"}>{this.state.hostData.title}</div>
                    </div>
                    <div>
                        <ConnectedUserAccount />
                    </div>
                </div>
                <div className={"book-trip"}>
                    <div className={"trip-content"}>
                        <div className={"flex"}>
                            <div className={"narrow"}>
                                <H2> Get to know us </H2>
                                {this.renderHosts(this.state.hostData.people)}
                                <div className={"text-section"}>
                                    {this.state.hostData.about}
                                </div>
                                <div className={"center"}>
                                    <YouTube
                                        videoId="4xdBqqaOP9w"
                                        opts={{
                                            height: '390',
                                            width: '800',
                                            playerVars: {
                                                autoplay: 0
                                            }}}
                                    />
                                </div>
                                <H2> Where are we going next </H2>
                                {this.state.hostData.trips.map((trip) => <LocationThumbnail bookID={trip}/>)}

                                <H2> The fine print, how does it work </H2>
                                {this.renderQA(this.state.hostData.qa)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderHosts = (hosts: IHostPerson[]) => {
        return (
            <div className={"organizers"}>
                {hosts.map((host, i) => <div key={i} className={"host"}>
                    <img className={"picture"} src={host.profilePicture} />
                    <div className={"name"}> {host.name} </div>
                    <a href={host.instagram} target={"_blank"} className="instagram-logo"></a>
                </div>)}
            </div>
        );
    }

    renderQA = (qas: {q: string; a: string;}[]) => {
        return (
            <div className={"qas"}>
                {qas.map((qa, i) => <div key={i} className={"qa"}>
                    <div className={"q"}>
                        {_.upperCase(qa.q)}
                    </div>
                    <div className={"a"}> {qa.a} </div>
                </div>)}
            </div>
        );
    }

}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
    return {
        setTripLocation: (tripLocation: IGooglePlaceDetails) => {
            dispatch(SET_TRIP_LOCATION(tripLocation));
        },
        hydrateCurrentTrip: (trip: ITrip) => {
            dispatch(hydrateCurrentTrip(trip));
        },
    };
};

export const ConnectedHostScreen = connect(null, mapDispatchToProps)(HostScreen);