import * as React from "react";
import {Draggable} from "react-beautiful-dnd";
import {IEventData} from "../types/types";
import {ConnectedEventCard} from "./EventCard";
import {connect} from "react-redux";
import {deleteEvent, moveEventFromDayToWishlist, moveEventToDay} from "../redux/actions/batchedActions";
import {IGlobalState} from "../redux/GlobalState";
import {Alert, ContextMenu, Dialog, Intent, Menu, MenuItem} from "@blueprintjs/core";
import {MouseEvent} from "react";
import {EditableEvent} from "./EditableEvent";
import {ConnectedEditFirebaseEvent} from "./EditFirebaseEvent";

interface IOwnProps {
    event: IEventData;
    index: number;
    disableDrag: boolean;
}
interface IStateProps {
    moveToList: {name: string; index: number}[];
    eventIsinWishlist: boolean;
    tripID: string;
    isTrip: boolean;
}
interface IDispatchProps {
    moveEvent(id: number): void;
    deleteEvent(tripID: string): void;
    moveBackToWishlist(): void;
    deleteEventFromDay: (tripID: string) => void;
}
interface IState {
    isEditing: boolean;
    isDeleteAlertOpen: boolean;
}
type IProps = IOwnProps & IStateProps & IDispatchProps;
class DraggableEventCard extends React.Component<IProps, IState> {
    state = {
        isDeleteAlertOpen: false,
        isEditing: false,
    };
    public render() {
        return(
            <div tabIndex={0} onContextMenu={this.handleRightClick} onDoubleClick={this.handleDoubleClick}>
                <Draggable isDragDisabled={this.props.disableDrag} draggableId={this.props.event.id.toString()} index={this.props.index}>
                {(provided, snapshot) => {
                    const style = {
                        ...provided.draggableProps.style,
                    };
                    return (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={style}
                    >
                        <ConnectedEventCard event={this.props.event} eventID={this.props.event.id}/>
                    </div>
                    );
                }}
                </Draggable>
                <Alert
                    isOpen={this.state.isDeleteAlertOpen}
                    cancelButtonText={"Cancel"}
                    confirmButtonText={"Delete"}
                    onCancel={() => this.setState({isDeleteAlertOpen: false})}
                    intent={Intent.DANGER}
                    onConfirm={this.deleteEvent}>
                    <p>Are you sure you want to <b>delete </b>the event </p>
                </Alert>
                {this.renderEditEvent()}
            </div>
                );
    }

    handleRightClick = (e: MouseEvent) => {
        e.preventDefault();
        ContextMenu.show(this.renderContextMenu(), {left: e.clientX, top: e.clientY})
    }
    handleDoubleClick = (e: MouseEvent) => {
        this.setState({isEditing: true});
    }
    public renderContextMenu() {
        // return a single element, or nothing to use default browser behavior
        return (
            <Menu>
                <MenuItem icon={"edit"} onClick={() => this.setState({isEditing: true})} text="Edit" />
                {this.props.eventIsinWishlist ?
                    null
                    :
                    <MenuItem icon={"arrow-left"} onClick={this.backToWishlist} text="Back to wishlist"/>
                }
                <MenuItem icon={"delete"} onClick={this.openDeleteAlert} text="Delete"/>
            </Menu>
        );
    }

    private openDeleteAlert = () => {
        this.setState({isDeleteAlertOpen: true});
    }

    private deleteEvent = () => {
        if (this.props.eventIsinWishlist) {
            this.props.deleteEvent(this.props.tripID);
        } else  {
            this.props.deleteEventFromDay(this.props.tripID);
        }
        this.setState({isDeleteAlertOpen: false});
    }

    private backToWishlist = () => {
        console.log("move event bacl to wishlist");
        this.props.moveBackToWishlist();
    }

    private renderEditEvent = () => {
        return (
            <Dialog
                isOpen={this.state.isEditing}
                canEscapeKeyClose={false}
                canOutsideClickClose={false}
                enforceFocus={true}
                style={{width: "750px"}}
            >
                <ConnectedEditFirebaseEvent onCloseEdit={() => this.setState({isEditing: false})} isTrip={this.props.isTrip} eventID={this.props.event.id} eventData={[this.props.event]} />

            </Dialog>
        )
    }

}

const mapStateToProps = (state: IGlobalState, ownProps: IOwnProps): IStateProps => {
    return {
        moveToList: state.currentTrip.days.map((day, i) => {return {name: day.name, index: i}}),
        eventIsinWishlist: state.currentTrip.wishlist.includes(ownProps.event.id),
        tripID: state.currentTrip.firestoreId,
        isTrip: state.session.isTrip,
    };
};

const mapDispatchToProps = (dispatch: any, ownProps: IOwnProps): IDispatchProps => {
    return {
        moveEvent: (dayId: number) => {
            dispatch(moveEventToDay(ownProps.event.id, dayId, -1)); // always add the event to the end of the day
        },
        deleteEvent: (tripID: string) => {
            dispatch(deleteEvent(tripID, ownProps.event.id));
        },
        deleteEventFromDay: (tripID: string) => {
            dispatch(deleteEvent(tripID, ownProps.event.id));
        },
        moveBackToWishlist: () => {
            dispatch(moveEventFromDayToWishlist(ownProps.event.id))
        }
    };
};

export const ConnectedDraggableEventCard = connect(mapStateToProps, mapDispatchToProps)(DraggableEventCard);
