import * as React from "react";
import {Intent, Spinner, Tag} from "@blueprintjs/core";
import "../styles/EventCard.css";
import * as _ from "lodash";
import "../styles/TagList.css";
import {IBookPage} from "../redux/GlobalState";
import {firestore} from "../util/firestore";

interface IProps {
    bookID: string;
}
interface IState {
    bookPage: IBookPage | null;
}
export class LocationThumbnail extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            bookPage: null,
        }
    }
    async componentDidMount() {
        const loadResp = await firestore.loadBookPage(this.props.bookID);
        console.log("response is: ", loadResp);
        if (loadResp.bookPage) {
            this.setState({bookPage: loadResp.bookPage});
        } else {
            console.log(loadResp.err);
        }
    }

    render() {
        if (this.state.bookPage) {
            return (
                <div className={"location-thumbnail"}>
                    <img src={this.state.bookPage.images[0]}/>
                    <div className={"emoji"}> 🇨🇴 </div>
                    <div className={"trip-name"}> {this.state.bookPage.title} </div>
                </div>
            )
        } else {
            return (
                <div className={"location-thumbnail"}>
                    <Spinner size={Spinner.SIZE_STANDARD}/>
                </div>
            )
        }

    }

}
