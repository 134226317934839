import {getNextId, initialState} from "../GlobalState";
import {ActionType, getType} from "typesafe-actions";
import * as eventActions from "../actions/eventActions";
import update from 'immutability-helper';

export type EventActionsType = ActionType<typeof eventActions>;

export const eventsReducer = (state = initialState.currentTrip.eventData, action: EventActionsType) => {
    switch (action.type) {
        case getType(eventActions.ADD_EVENT):
            const id = action.payload.id;
            return update(state, {[id]: {$set: action.payload}});
        case getType(eventActions.DELETE_EVENT):
            const eventID = action.payload;
            return update(state, {$unset: [eventID]});
        case getType(eventActions.HYDRATE_EVENTS):
            return action.payload;
        default:
            return state;
    }
};