import {firestore} from "../../util/firestore";
import {IEventData} from "../../types/types";
import {Dispatch} from "redux";
import firebase from "firebase";
import {createAction} from "typesafe-actions";
import {IBoard} from "../GlobalState";
import {ADD_TO_WISHLIST} from "./wishlistActions";


export const HYDRATE_CURRENT_BOARD = createAction("HYDRATE_CURRENT_BOARD", (resolve) => {
    return (board: IBoard) => resolve(board);
});

export const ADD_EVENT_ID_TO_BOARD = createAction("ADD_EVENT_ID_TO_BOARD", (resolve) => {
    return (eventID: string) => resolve(eventID);
});

export const ADD_EVENT_DATA_TO_BOARD = createAction("ADD_EVENT_DATA_TO_BOARD", (resolve) => {
    return (eventID: string, eventData: IEventData) => resolve({eventID, eventData});
});

export const DELETE_EVENT_DATA_FROM_BOARD = createAction("DELETE_EVENT_DATA_FROM_BOARD", (resolve) => {
    return (eventID: string) => resolve(eventID);
});

export const SET_BOARD_NAME = createAction("SET_BOARD_NAME", (resolve) => {
    return (boardName: string) => resolve(boardName);
});


export const addEvent = (boardID: string, newEvent: IEventData) => async (dispatch: Dispatch) => {
    console.log("dispatching adding this event... ", {newEvent, boardID});
    const firebaseEvent = await firestore.events.add(newEvent);
    return firestore.boards.doc(boardID).collection("events").doc(firebaseEvent.id).set({});
    // return firestore.boards.doc(boardID).update({
    //     eventIDs: firebase.firestore.FieldValue.arrayUnion(firebaseEvent.id)
    // })
};


export const fetchEvents = (boardID: string) => async (dispatch: Dispatch) => {
    const boardRef = firestore.boards.doc(boardID).collection("events");
    boardRef.onSnapshot( snapshot => {
        console.log("events changes with value...: ", snapshot);
        const source = snapshot.metadata.hasPendingWrites ? "Local" : "Server";
        console.log(source, " docs: ", snapshot.docs);
        snapshot.docChanges().forEach((change) => {
            console.log("change is: ", change);
            const eventID = change.doc.id;
            if (change.type === "added") {
                firestore.events.doc(eventID).get()
                    .then((doc) => {
                        const event = doc.data() as IEventData;
                        event.id = eventID;
                        dispatch(ADD_EVENT_DATA_TO_BOARD(eventID, event));
                    });
                dispatch(ADD_EVENT_ID_TO_BOARD(eventID));
            }
        });
    });
};
