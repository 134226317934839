import * as React from "react";
import {Button, ButtonGroup, Card, H2, H3, H4, Icon, Intent, MenuItem, Spinner} from "@blueprintjs/core";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import * as _ from "lodash";
import {
    getPlaceDetailsFromPlaceId,
    getPublicBoards,
    getPublicTrips,
    IPublicTrip,
    suggestPlaceFromQuery
} from "../api/server";
import {Suggest} from "@blueprintjs/select";
import {areLocationsEqual, IGooglePlaceSuggestion, locationSelectProps} from "../util/locations";
import {IGooglePlaceDetails} from "../types/types";
import {SET_TRIP_LOCATION} from "../redux/actions/paramsActions";
// @ts-ignore
import * as ReactRotatingText from "react-rotating-text";
import {ConnectedUserAccount} from "../components/UserAccount";
import {firestore} from "../util/firestore";
import {IBoard, IBoardLandingPreview} from "../redux/GlobalState";
import {ConnectedBoardEvent} from "../components/BoardEvent";
import {ConnectedLandingNavbar} from "../components/LandingNavbar";
import Gallery from "react-photo-gallery";

interface IState {
    boards: IBoardLandingPreview[];
    loading: boolean;
}

export class BoardLandingScreen extends React.Component<{}, IState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            boards: [],
            loading: false,
        }
    }

    async componentDidMount() {
        getPublicBoards()
            .then((boards) => {
                console.log("boards list: ", boards);
                if (!_.isEmpty(boards)) {
                    this.setState({boards});
                }
            });

    }
    render() {
        return (
            <div className={"landing-board"}>
                <div className={"banner"}>
                    We are working as fast 🏃🏽‍♂️ as we can, but currently Toodle (beta) is only supported on desktop. Please visit us from your computer 💻
                </div>
                <ConnectedLandingNavbar />
                <div className={"landing-main"}>
                    <div className={"all-boards"}>
                        {this.state.boards.map((board, i) => this.renderBoard(board, i))}
                    </div>
                    {_.isEmpty(this.state.boards) ? <Spinner /> : null}

                </div>
            </div>
        );
    }

    renderBoard(board: IBoardLandingPreview, key: number) {
        if (board.images && board.images.length > 0) {
            return (
                <div key={key} className={"board-section"} onClick={() => {window.location.href = "/board/" + board.firestoreId}}>
                    <H3> {board.boardName} </H3>
                    <div className={"landing-board-content"}>
                        {this.renderImages(board.images)}
                    </div>
                    <div className={"board-hover"}>
                        Click to open this board in a new tab
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
    renderImages = (images: string[]) => {
        const photos = _.uniq(images).slice(0,4).map((im) => {
            return {
                src: im,
                width: 1,
                height: 1,
            }
        });
        return (
            <div className={"trip-photos"}>
                <Gallery targetRowHeight={3} photos={photos} />
            </div>
        );
    }

}