import * as React from "react";

interface IProps {
    inputs: string[];
    initialSelection: string;
    onChange: (value: string) => void;
}
interface IState {
    selected: string;
}
export class ToggleButton extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            selected: props.initialSelection,
        }
    }
    render() {
        return (
            <div className={"toggle-button"}>
                {this.props.inputs.map((value, i) => {
                    return (
                        <div key={i} onClick={() => this.clicked(value)} className={value === this.state.selected ? "toggle-element clicked" : "toggle-element"}> {value} </div>
                    );
                })}
            </div>
        );
    }

    clicked = (value: string) => {
        if (value !== this.state.selected) {
            this.setState({selected: value});
            this.props.onChange(value);
        }
    }
}