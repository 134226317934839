import {IDayContent, IEventData, IGooglePlaceDetails} from "../types/types";
import { Card } from "@blueprintjs/core";

let nextStateId = 0;
export const getNextId = () => {
    const id = nextStateId;
    nextStateId += 1;
    return id;
};
export const setNextId = (id: number) => {
    nextStateId = id;
};

export const eventCategories = [
    "Landmark",
    "Adventure",
    "Shopping",
    "Food",
    "Hotel",
    "Transport",
    "Free-time",
    "Tour",
    "Museum",
    "Night Life",
];

export interface IStateParams {
    nextId: number;
    schemaVersion: number;
    loggedInUser: any;
    authLoading: boolean;
    name: string;
    tripLocation: IGooglePlaceDetails | null;
    tripName: string;
}
export interface ITrip {
    firestoreId: string;
    eventData: {[id: string]: IEventData};
    wishlist: string[];
    params: IStateParams;
    days: IDayContent[];
    owner: string;
}
export interface IBoard {
    firestoreId: string;
    eventIDs: string[];
    eventData: {[id: string]: IEventData};
    params: {
        schemaVersion: number;
    };
    boardName: string;
    location: IGooglePlaceDetails | null;
    owner: string;
}
export interface IBoardLandingPreview {
    firestoreId: string;
    images: string[];
    boardName: string;
}
export interface IHost {
    title: string;
    about: string;
    people: IHostPerson[];
    trips: string[];
    video: string;
    qa: {q: string; a: string}[];
}
export interface IHostPerson {
    instagram: string;
    name: string;
    profilePicture: string;
}
export interface IBookPage {
    title: string;
    images: string[];
    subtitle: string;
    pitch: string;
    duration: string;
    cities: string[];
    cost: string;
    host: string;
    tripID: string;
    dates: IBookDate[];
    hosts: IBookHost[];
    owner: string;
}
export interface IBookDate {
    dateName: string;
    date: Date;
    spots: number;
    price: number;
}
export interface IBookHost {
    image: string;
    instagram: string;
    name: string;
}
export interface ISelectedCard {
    id: string;
    source: "card" | "map";
}
export interface ISessionState {
    loggedInUser: firebase.User | null;
    authLoading: boolean;
    selectedCard: ISelectedCard;
    isTrip: boolean;
}
export interface IGlobalState {
    currentTrip: ITrip;
    currentBoard: IBoard;
    session: ISessionState;
}

export const emptyTripData = (): ITrip => {
    return {
        firestoreId: "",
        eventData: {},
        wishlist: [],
        params: {
            nextId: 0,
            schemaVersion: 0,
            loggedInUser: null,
            authLoading: true,
            name: "",
            tripLocation: null,
            tripName: "",
        },
        days: [{name: "Day 1", description: "non", events:[]}],
        owner: "",
    }
}

export const emptyBoardData = (): IBoard => {
    return {
        firestoreId: "",
        eventIDs: [],
        eventData: {},
        boardName: "",
        location: null,
        params: {
            schemaVersion: 0,
        },
        owner: "",
    }
}

export const initialState: IGlobalState = {
    currentTrip: emptyTripData(),
    currentBoard: emptyBoardData(),
    session: {
        loggedInUser: null,
        authLoading: true,
        selectedCard: {
            id: "",
            source: "map",
        },
        isTrip: false,
    }
};