import * as React from "react";
import { firestore } from "../util/firestore"
import {IGlobalState, ITrip, getNextId, IBoard} from "../redux/GlobalState";
import { connect } from "react-redux";
import {
    Button,
    Callout,
    Dialog,
    FormGroup,
    InputGroup,
    Intent,
    Tag,
    Popover,
    PopoverInteractionKind,
    Position,
    ButtonGroup, Spinner
} from "@blueprintjs/core";
import * as _ from "lodash";
import "../styles/SaveButton.css";
import { Dispatch } from "redux";
import { SET_NEXT_ID } from "../redux/actions/paramsActions";
import {ConnectedLoginBox} from "./LoginBox";

interface IStateProps {
  currentTrip: ITrip;
  currentBoard: IBoard;
  isLoggedIn: boolean;
  user: any;
  authLoading: boolean;
}
interface IOwnProps {
}
interface IDispatchProps {
  setNextId(newID: number): void;
}
type IProps = IOwnProps & IStateProps & IDispatchProps;

interface IState {
  loginDialog: boolean;
  loginError: string;
  isLoading: boolean;
  cloneDialog: boolean;
  cloneLoading: boolean;
  oldCurrentTrip: ITrip;
  oldCurrentBoard: IBoard;
  documentType: string;
}

class SaveButton extends React.Component<IProps, IState> {
  private autoSaveTimer: any;
  constructor(props: IProps) {
    super(props);
      const documentType: string = window.location.pathname.split('/')[1];
      console.log("document type: ", documentType);
      this.state = {
      isLoading: false,
      loginDialog: false,
      loginError: "",
      cloneDialog: false,
      cloneLoading: false,
      oldCurrentTrip: props.currentTrip,
      oldCurrentBoard: props.currentBoard,
      documentType,
    }

    this.autoSaveTimer = setInterval(() => {
        if (this.props.user && this.state.documentType === "trip" && this.props.user.email === this.props.currentTrip.owner) {
            if (!_.isEqual(this.state.oldCurrentTrip, this.props.currentTrip)) {
                const isOldTripEmpty = _.isEmpty(this.state.oldCurrentTrip.firestoreId);
                // console.log("state is different, saving.. new", this.props.currentTrip);
                // console.log("old", this.state.oldCurrentTrip);
                this.setState({oldCurrentTrip: this.props.currentTrip});
                if (!isOldTripEmpty) {
                    console.log("SAVE Document");
                    this.onClickSave();
                }
            }
        } else if (this.props.user && this.state.documentType === "board" && this.props.user.email === this.props.currentBoard.owner) {
            if (!_.isEqual(this.state.oldCurrentBoard, this.props.currentBoard)) {
                const isOldBoardEmpty = _.isEmpty(this.state.oldCurrentBoard.firestoreId);
                // console.log("state is different, saving.. new", this.props.currentTrip);
                // console.log("old", this.state.oldCurrentTrip);
                this.setState({oldCurrentBoard: this.props.currentBoard});
                if (!isOldBoardEmpty) {
                    console.log("SAVE Document");
                    this.onClickSave();
                }
            }
        }
    }, 10000);
  }

  saveCurrentTrip = async () => {
    this.props.setNextId(getNextId());
    await firestore.updateTrip(this.props.currentTrip);
    this.setState({isLoading: false});
  }

    saveCurrentBoard = async () => {
        this.props.setNextId(getNextId());
        await firestore.updateBoard(this.props.currentBoard);
        this.setState({isLoading: false});
    }

  render() {
    const saveButton = (this.state.isLoading || this.props.authLoading) ?
        <div className={"navbar-spinner"}>
            <Spinner size={30} />
        </div>
     : <button className={"navbar-button"} onClick={this.onClickSave}>
        Save
      </button>;

    return (
        <div>
          {saveButton}
          {this.renderSignIn()}
          {this.renderClone()}
        </div>
    );
  }

  renderSignIn() {
    return (
        <Dialog
            isOpen={this.state.loginDialog}
            title={"Who should we keep this trip for?"}
            icon={"user"}
            canEscapeKeyClose={false}
            canOutsideClickClose={false}
            enforceFocus={true}
            style={{width: "500px", lineHeight: "18px"}}
            onClose={() => this.setState({loginDialog: false})}
        >
            <ConnectedLoginBox onLoginComplete={this.onLoginComplete} onLoginFailed={this.onLoginFailed}/>
        </Dialog>
    );
  }

    renderClone() {
      if (this.state.documentType === "trip") {

          return (
              <Dialog
                  isOpen={this.state.cloneDialog}
                  title={"You need to copy trip before you save"}
                  icon={"duplicate"}
                  canEscapeKeyClose={true}
                  canOutsideClickClose={true}
                  enforceFocus={true}
                  style={{width: "500px", lineHeight: "18px"}}
                  onClose={() => this.setState({cloneDialog: false})}
              >
                  <div className={"clone-dialog"}>
                      You are not the owner of this trip, so you can't save changes to it. But don't worry, copy the
                      trip
                      (we will move over any changes you made). This will create a new trip for you with the same
                      content,
                      but you will be the owner and you can save any future changes.

                      <Button className={"clone-button"} icon={"duplicate"} loading={this.state.cloneLoading}
                              intent={Intent.SUCCESS} onClick={this.cloneTrip}> Copy Trip </Button>
                  </div>
              </Dialog>
          );
      } else if (this.state.documentType === "board") {
          return (
              <Dialog
                  isOpen={this.state.cloneDialog}
                  title={"You need to copy board before you save"}
                  icon={"duplicate"}
                  canEscapeKeyClose={true}
                  canOutsideClickClose={true}
                  enforceFocus={true}
                  style={{width: "500px", lineHeight: "18px"}}
                  onClose={() => this.setState({cloneDialog: false})}
              >
                  <div className={"clone-dialog"}>
                      You are not the owner of this board, so you can't save changes to it. But don't worry, copy the
                      board
                      (we will move over any changes you made). This will create a new board for you with the same
                      content,
                      but you will be the owner and you can save any future changes.

                      <Button className={"clone-button"} icon={"duplicate"} loading={this.state.cloneLoading}
                              intent={Intent.SUCCESS} onClick={this.cloneBoard}> Copy Trip </Button>
                  </div>
              </Dialog>
          );
      }
    }

    onClickSave = () => {
        if (this.props.isLoggedIn) {
            if (this.props.user && this.state.documentType === "trip" && this.props.user.email === this.props.currentTrip.owner) {
                this.setState({isLoading: true});
                this.saveCurrentTrip();
            } else if (this.props.user && this.state.documentType === "board" && this.props.user.email === this.props.currentBoard.owner) {
                this.setState({isLoading: true});
                this.saveCurrentBoard();
            } else { // current user is not the owner, ask to clone
                this.setState({cloneDialog: true});
            }
        } else {
            this.setState({loginDialog: true});
        }
    }
    onLoginComplete = () => {
        this.saveCurrentTrip();
        this.setState({loginDialog: false});
    }

    onLoginFailed = () => {
      console.log("login didnn't work from the save button");
    }

    cloneTrip = async () => {
        this.setState({cloneLoading: true});
        const maybeNewID: string | null = await firestore.cloneTrip(this.props.currentTrip);
        if (maybeNewID) {
            window.open(`/trip/${maybeNewID}`, "_blank");
        } else {
            this.setState({cloneLoading: false});
            alert(`Something went wrong while cloning your trip! ${maybeNewID} ${firestore.auth.currentUser ? firestore.auth.currentUser.email : "no email?"}`);
        }
    }

    cloneBoard = async () => {
        this.setState({cloneLoading: true});
        const maybeNewID: string | null = await firestore.cloneBoard(this.props.currentBoard);
        if (maybeNewID) {
            window.open(`/board/${maybeNewID}`, "_blank");
        } else {
            this.setState({cloneLoading: false});
            alert(`Something went wrong while cloning your board! ${maybeNewID} ${firestore.auth.currentUser ? firestore.auth.currentUser.email : "no email?"}`);
        }
    }

}

const mapStateToProps = (state: IGlobalState): IStateProps => {
  return {
      currentTrip: state.currentTrip,
      isLoggedIn: Boolean(state.session.loggedInUser),
      user: state.session.loggedInUser,
      authLoading: state.session.authLoading,
      currentBoard: state.currentBoard,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return {
      setNextId: (newID: number) => {
          dispatch(SET_NEXT_ID(newID));
      },
  };
};

export const ConnectedSaveButton = connect(mapStateToProps, mapDispatchToProps)(SaveButton);