import * as React from "react";
import { ITrip, IGlobalState } from "../redux/GlobalState";
import {
    EditableText,
    H4,
    Icon,
    Intent,
    Menu,
    MenuDivider,
    MenuItem,
    Navbar,
    Popover,
    Position
} from "@blueprintjs/core";
import { ConnectedUserAccount } from "./UserAccount";
import { ConnectedSaveButton } from "./SaveButton";
import { ConnectedCloneButton } from "./CloneButton";
import { connect } from "react-redux";
import {ConnectedNewEvent} from "./NewEvent";
import {UPDATE_USER} from "../redux/actions/sessionActions";
import {hydrateCurrentTrip} from "../redux/actions/batchedActions";
import {Dispatch} from "redux";
import {SET_TRIP_NAME} from "../redux/actions/paramsActions";

interface IStateProps {
  loggedInUser: firebase.User | null;
  currentTrip: ITrip;
  tripName: string;
}
interface IOwnProps {
    isTrip: boolean;
    onViewStateChange?: (newState: string) => void;
    activeScreen?: string;
}
interface IDispatchProps {
    setTripName: (name: string) => void;
}

type IProps = IOwnProps & IStateProps & IDispatchProps;

class ToodleNavbar extends React.Component<IProps, {}> {
  render() {
    // trip has no owner -> enable save
    // user not logged in -> enable save
    // user logged in and does own -> enable save
    // user logged in and does not own -> disable save
    const saveEnabled = !this.props.currentTrip.owner
      || !this.props.loggedInUser
      || this.props.loggedInUser && this.props.loggedInUser.email === this.props.currentTrip.owner;
    return (
      <Navbar className={"navbar-style"}>
        <Navbar.Group className={"main-navbar"}>
            <div className={"main-navbar"}>
                <div className={"navbar-left"}>
                    <div className={"nav-item logo"}> T. </div>
                    {this.props.isTrip && this.props.onViewStateChange && this.props.activeScreen ?
                    <div className={"nav-actions"}>
                        <div className="vl"></div>
                        <div className={this.props.activeScreen === "map" ? "nav-icon selected" : "nav-icon"} onClick={() => this.props.onViewStateChange!("map")}>
                            <Icon icon={"lightbulb"} iconSize={20} color={"#898B8B"}/>
                            <span> Collect </span>
                        </div>
                        <div className={this.props.activeScreen === "plan" ? "nav-icon selected" : "nav-icon"} onClick={() => this.props.onViewStateChange!("plan")}>
                            <Icon icon={"list"} iconSize={20} color={"#898B8B"}/>
                            <span> Plan </span>
                        </div>
                    </div>
                    : null}
                    {/*<div className={"trip-name"}><EditableText className={"trip-name"} value={this.props.tripName} placeholder={"Enter Trip Name"} onChange={this.props.setTripName}/></div>*/}
                </div>
                <div className={"navbar-center"}>
                    <div className={"nav-item"}> <ConnectedNewEvent isTrip={this.props.isTrip} /> </div>
                </div>
                <div className={"navbar-right"}>
                    <div className={"nav-item mobile-hide"}>
                        <div>
                            <button className={"navbar-button"} onClick={() => {
                                window.open("mailto:muneezapatel@gmail.com", "_blank");
                            }}>
                                Send Feedback
                            </button>
                        </div>
                    </div>
                    <div className={"nav-item mobile-hide"}>
                        <ConnectedSaveButton/>
                    </div>
                    <div className={"nav-item mobile-hide"}> <ConnectedUserAccount /> </div>
                    <div className={"nav-item mobile-nav-item"}>
                        <Popover minimal={true} content={this.mobileMenu()} position={Position.BOTTOM}>
                            <Icon icon={"menu-closed"} iconSize={20}/>
                        </Popover>
                    </div>
                </div>
            </div>
        </Navbar.Group>
        <div className={"slide-right"}>🚌</div>
      </Navbar>
    )
  }
    mobileMenu = () => {
        return (
            <Menu>
                <ConnectedSaveButton/>
                <MenuDivider />
                <ConnectedUserAccount />
                <MenuDivider />
                <MenuItem text={"Send Feedback"} onClick={() => {
                    window.open("mailto:muneezapatel@gmail.com", "_blank");
                }}/>
            </Menu>
        )
    }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
    return {
        setTripName: (name: string) => {
            dispatch(SET_TRIP_NAME(name));
        },
    };
};
const mapStateToProps = (state: IGlobalState): IStateProps => {
  return {
      currentTrip: state.currentTrip,
      tripName: state.currentTrip.params.tripName,
      loggedInUser:state.session.loggedInUser,
  };
};

export const ConnectedToodleNavbar = connect(mapStateToProps, mapDispatchToProps)(ToodleNavbar);