import * as React from "react";
import ReactDOM from "react-dom";
import {IDayContent, IEventData} from "../types/types";
import {
    Card,
    Icon,
    Intent,
    Menu, MenuDivider, MenuItem,
    Popover,
    Position,
    Toaster,
    IToaster, Dialog,
} from "@blueprintjs/core";
import "../styles/EventCard.css";
import {TagList} from "./TagList";
import {IGlobalState, emptyTripData, ISelectedCard} from "../redux/GlobalState";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {ExpandableText} from "./ExpandableText";
import {ArrowButton} from "./ArrowButton";
import {CheckButton} from "./CheckButton";
import * as _ from "lodash";
import {firestore} from "../util/firestore";
import {ADD_EVENT_DATA_TO_BOARD} from "../redux/actions/boardActions";
import {deleteEvent, moveEventFromDayToWishlist, moveEventToDay} from "../redux/actions/batchedActions";
import {assignedDayForEvent} from "../redux/selectors";
import {ADD_EVENT} from "../redux/actions/eventActions";
import {ConnectedEditFirebaseEvent} from "./EditFirebaseEvent";

type ITagType = "day" | "category" | "none";
interface IOwnProps {
    eventID: string;
    onClickEvent: () => void;
    tagType: ITagType;
}
interface IStateProps {
    moveToList: {name: string; index: number}[];
    assignedDay: IDayContent | undefined;
    eventData: IEventData | null;
    isTrip: boolean;
    tripID: string;
}
interface IDispatchProps {
    populateEventDataInState: (eventID: string, eventData: IEventData) => void;
    removeFromDay: () => void;
    moveEvent(dayID: number): void;
    addEventToState: (event: IEventData) => void;
    deleteEvent: (tripID: string) => void;
}
type IProps = IOwnProps & IStateProps & IDispatchProps;

interface IState {
    cardClass: string;
    isEditing: boolean;
}

class NewTripEvent extends React.Component<IProps, IState> {

    private toastRef: IToaster | null = null

    constructor(props: IProps) {
        super(props);
        this.state = {
            cardClass: "event-card",
            isEditing: false,
        }
    }

    public render() {
        if (!this.props.eventData) {
            return (
                <div className={"loading-event"}>
                    Loading
                </div>
            );
        } else {
            return (
                <div className={"board-event"} onClick={this.props.onClickEvent}>
                    <div className={"event-image-wrapper"}>
                        <img className={"event-image"} src={this.props.eventData.image}/>
                        <div className={"description"}>
                            {this.props.eventData.description}
                        </div>
                    </div>
                    <div className={"event-details"}>
                        <div className={"event-name"}>
                            {this.props.eventData.name}
                        </div>
                        <div className={"event-bottom"}>
                            <div className={"tags"}>
                                {this.renderTag()}
                            </div>
                            <Popover position={Position.RIGHT} content={this.renderMoveToMenu()} target={<div className={"move-icon"}>
                                <Icon color={"#9D9D9D"} icon={"more"} />
                            </div>} />
                        </div>

                    </div>
                    {this.renderEditEvent()}
                </div>
            );
        }

    }

    private renderEditEvent = () => {
        return (
            <Dialog
                isOpen={this.state.isEditing}
                canEscapeKeyClose={false}
                canOutsideClickClose={false}
                enforceFocus={true}
                style={{width: "750px"}}
            >
                <ConnectedEditFirebaseEvent onCloseEdit={() => this.setState({isEditing: false})} isTrip={this.props.isTrip} eventID={this.props.eventID} eventData={[this.props.eventData!]} />

            </Dialog>
        )
    }

    public renderMoveToMenu() {
        return (
            <Menu>
                <MenuItem text={"Return to Usassiged"} icon={"tree"} onClick={this.props.removeFromDay}/>
                <MenuItem text={"Edit"} icon={"edit"} onClick={() => this.setState({isEditing: true})}/>
                <MenuItem intent={Intent.DANGER} text={"Delete"} icon={"delete"} onClick={() => this.props.deleteEvent(this.props.tripID)}/>
                <MenuDivider/>
                {this.props.moveToList.map((item, i) => {
                    // const intent = this.props.assignedDay === item.id ? Intent.SUCCESS : Intent.NONE;
                    const intent = Intent.NONE;
                    return (<Menu.Item intent={intent} key={i} onClick={() => this.handleMove(item.index)} text={item.name} />);
                })}
            </Menu>
        );
    }

    public handleMove(id: number) {
        console.log("moving to... ", id);
        this.props.moveEvent(id);
    }

    renderTag = () => {
        if (this.props.eventData) {
            if (this.props.tagType === "day") {
                return (
                    <div className={"name-tag"}>
                        {this.props.assignedDay ? this.props.assignedDay.name : "Unassigned"}
                    </div>
                );
            } else if (this.props.tagType === "category") {
                return (
                    <TagList selected={[]} tags={this.props.eventData.tags} interactive={false}/>
                );
            } else {
                return null;
            }
        }
    }


}

const mapStateToProps = (state: IGlobalState, ownProps: IOwnProps): IStateProps => {
    return {
        moveToList: state.currentTrip.days.map((day, i) => {return {name: day.name, index: i}}),
        assignedDay: assignedDayForEvent(state, ownProps.eventID),
        eventData: state.currentBoard.eventData[ownProps.eventID],
        isTrip: state.session.isTrip,
        tripID: state.currentTrip.firestoreId,
    };
};

const mapDispatchToProps = (dispatch: any, ownProps: IOwnProps): IDispatchProps => {
    return {
        moveEvent: (dayIndex: number) => {
            dispatch(moveEventToDay(ownProps.eventID, dayIndex, -1)); // always add the event to the end of the day
        },
        populateEventDataInState: (eventID: string, eventData: IEventData) => {
            dispatch(ADD_EVENT_DATA_TO_BOARD(eventID, eventData));
        },
        removeFromDay: () => {
            dispatch(moveEventFromDayToWishlist(ownProps.eventID));
        },
        addEventToState: (event: IEventData) => {
            dispatch(ADD_EVENT(event));
        },
        deleteEvent: (tripID: string) => {
            dispatch(deleteEvent(tripID, ownProps.eventID));
        },
    };
};

export const ConnectedNewTripEvent = connect(mapStateToProps, mapDispatchToProps)(NewTripEvent);
