import * as React from "react";

interface IProps {
    placeholder: string;
}
interface IState {

}
export class InputText extends React.Component<IProps, IState> {
    state = {
    };

    render = () => {
        return (
            <div className={"input-text"}>
                <input required={true} type={"text"}/>
                <label placeholder={this.props.placeholder}/>
            </div>
        )
    }
}
