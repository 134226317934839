import * as React from "react";
import { ConnectedToodleNavbar } from "../components/ToodleNavbar";
import {firestore} from "../util/firestore";
import {emptyBoardData, IBoard, IGlobalState, ITrip, setNextId} from "../redux/GlobalState";
import {connect} from "react-redux";
import {Spinner, H1, H2, EditableText} from "@blueprintjs/core";
import {fetchEvents, SET_BOARD_NAME} from "../redux/actions/boardActions";
import {HYDRATE_CURRENT_BOARD} from "../redux/actions/boardActions";
import {ConnectedBoardEvent} from "../components/BoardEvent";
import {getBoardSections} from "../redux/selectors";
import {ConnectedBoardMap} from "../components/BoardMap";
import {ConnectedEventDetails} from "../components/EventDetails";
import * as _ from "lodash";
import {SET_TRIP_NAME} from "../redux/actions/paramsActions";
import {boardToTrip} from "../api/server";

interface IState {
    boardNotFound: boolean;
    isHydrated: boolean;
    board: IBoard;
    selectedEvent: string;
}
interface IStateProps {
    wishlistLength: number;
    eventIDs: string[];
    activeTags: string[];
    boardName: string;
}
interface IDispatchProps {
    hydrateCurrentBoard: (board: IBoard) => void;
    fetchEvents: (boardID: string) => void;
    setBoardName: (boardName: string) => void;
}
type IProps = IDispatchProps & IStateProps;
class BoardScreenComp extends React.Component<IProps, IState> {
    state = {
        boardNotFound: false,
        isHydrated: false,
        board: emptyBoardData(),
        selectedEvent: "",
        boardName: "",
    };

    async componentDidMount() {
        const docId: string = window.location.pathname.split('/')[2];
        const loadResp = await firestore.loadBoard(docId);
        if (loadResp.board) {
            this.props.hydrateCurrentBoard(loadResp.board);
            this.setState({isHydrated: true, board: loadResp.board});
            this.props.fetchEvents(loadResp.board.firestoreId);
        } else {
            console.log(loadResp.err);
            this.setState({boardNotFound: true});
        }
    }

    render() {
        if (!this.state.isHydrated) {
            return <div style={{position: "fixed", left: "50%", top: "50%"}}>
                <Spinner size={Spinner.SIZE_LARGE}/>
            </div>
        }
        return (
            <div className={"main-screen"}>
                <div>
                    <ConnectedToodleNavbar isTrip={false}/>
                </div>
                <div className={"main-board"}>
                    <div className={"events-panel"}>
                        <div className={"board-header"}>
                            <H2>
                                <EditableText value={this.props.boardName} placeholder={"Enter Board Name"} onChange={this.props.setBoardName}/>
                            </H2>
                            <div className={"go-on-trip"} onClick={this.goOnTrip}>
                                Go on this trip
                            </div>
                        </div>
                        <div className={"events-section"}>
                            <div className={"events"}>
                                {this.props.eventIDs.map((id, i) => {
                                    return <ConnectedBoardEvent key={i} eventID={id} onClickEvent={() => this.onEventClick(id)}/> ;
                                })}
                            </div>
                        </div>
                    </div>
                    <div className={"board-map"}>
                        <ConnectedBoardMap onClickMapMarker={this.onEventClick} isTrip={false}/>
                        <div className={"map-overlay"}>
                            {!_.isEmpty(this.state.selectedEvent) ? <ConnectedEventDetails unselectEvent={this.onUnselectEvent} selectedEventID={this.state.selectedEvent} /> : null}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onEventClick = (eventID: string) => {
        this.setState({selectedEvent: eventID});
    }
    onUnselectEvent = () => {
        this.setState({selectedEvent: ""});
    }

    goOnTrip = async () => {
        const tripID = await boardToTrip(this.state.board.firestoreId, this.state.board.owner);
        console.log(" got trip id: ", tripID);
        window.location.href = "/trip/" + tripID;
    }

}

const mapDispatchToProps = (dispatch: any): IDispatchProps => { // TODO: fix dispatch type
    return {
        hydrateCurrentBoard: (board: IBoard) => {
            console.log("hydratung boiard... nothing");
            dispatch(HYDRATE_CURRENT_BOARD(board));
        },
        fetchEvents: (boardID: string) => {
            dispatch(fetchEvents(boardID));
        },
        setBoardName: (name: string) => {
            dispatch(SET_BOARD_NAME(name));
        }
    };
};
const mapStateToProps = (state: IGlobalState): IStateProps => {
    return {
        wishlistLength: state.currentTrip.wishlist.length,
        eventIDs: state.currentBoard.eventIDs,
        activeTags: getBoardSections(state),
        boardName: state.currentBoard.boardName,
    };
};

export const BoardScreen =  connect(mapStateToProps, mapDispatchToProps)(BoardScreenComp);