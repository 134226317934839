import * as React from "react";
import {IDayContent, IDistanceResponse, IEventData} from "../types/types";
import {Card, EditableText, H4, Icon, Intent, Spinner, Tag} from "@blueprintjs/core";
import "../styles/EventCard.css";
import {TagList} from "./TagList";
import {IGlobalState} from "../redux/GlobalState";
import {connect} from "react-redux";
import * as _ from "lodash";
import {Droppable} from "react-beautiful-dnd";
import {ConnectedDraggableEventCard} from "./DraggableEventCard";
import {backendGetDistance} from "../util/parsers/backendParser";
import {newEventToWishlist} from "../redux/actions/batchedActions";
import {Dispatch} from "redux";
import {SET_DAY_TITLE} from "../redux/actions/dayActions";

interface IStateProps {
    events: IEventData[];
}
interface IDispatchProps {
    setDayName: (name: string) => void;
}
interface IOwnProps {
    dayContent: IDayContent;
    dayIndex: number;
    className?: string;
    droppableID: string;
    disableDnd: boolean;
    isUnassigned?: boolean;
}

interface IState {
    distanceMatrix: {[fromEventID: string]: {[toEventID: string]: IDistanceResponse | null}};
}
type IProps = IOwnProps & IStateProps & IDispatchProps;

class DayCard extends React.Component<IProps, IState> {
    state: IState = {
        distanceMatrix: {}
    }

    componentDidMount = () => {
        this.getDistInOrder();
    }

    componentDidUpdate = (prevProps: IProps) => {
        console.log("comp will recieve proops new check");
        if (prevProps.dayContent.events !== this.props.dayContent.events ||
        this.props.events !== prevProps.events) {
            this.getDistInOrder();
        }
    }

    render() {
        return (
            <div className={this.props.className}>
                <div className={"day-card day-expanded"}>
                    <div className={"day-card-header"}>
                        <div className={"day-name"}> <EditableText maxLength={25} onChange={this.props.setDayName} placeholder={"Day Title"} value={this.props.dayContent.name}/></div>
                        <div className={"day-event-count"}> {this.props.events.length} events</div>
                    </div>
                    <Droppable isDropDisabled={this.props.disableDnd} droppableId={this.props.droppableID}>
                        {(provided, snapshot) => (
                            <div className={snapshot.isDraggingOver ? "day-dragged" : "day-content-wrapper"} ref={provided.innerRef} {...provided.droppableProps}>
                                {this.renderExpandedCard()}
                            </div>
                        )}
                    </Droppable>
                </div>
            </div>
        )
    }

    renderExpandedCard = () => {
        return (
            <div className={"day-content"}>
                {this.props.events.map((event, i) => {
                    if (!event) {
                        return (
                            <div key={i} className={"loading-event"}>
                                Loading
                            </div>
                        );
                    }
                    return (
                        <div key={event.id}>
                            {i === 0 ? null : this.renderDistance(i)}
                            <ConnectedDraggableEventCard disableDrag={this.props.disableDnd} index={i} key={event.id} event={event}/>
                        </div>
                    )})}
                {this.props.events.length === 0 ? <div className={"day-empty"}> Drag cards here to have them in this day </div> : null}
            </div>
        );
    }

    renderDistance = (i: number) => {
        const fromEvent = this.props.events[i-1];
        const toEvent = this.props.events[i];
        if (fromEvent && toEvent) {
            const matrix = this.state.distanceMatrix[fromEvent.id];
            if (matrix && _.isNull(matrix[toEvent.id])) {
                return (
                    <div className={"day-distance"}>
                        <Icon icon={"cross"}/>
                    </div>
                );
            } else if (matrix && matrix[toEvent.id]) {
                return (
                    <div className={"day-distance"}>
                    <span>
                        <Icon icon={"drive-time"} className={"day-distance-icon"}/>
                        {this.state.distanceMatrix[fromEvent.id][toEvent.id]!.car.distance.text}
                    </span>
                        <span>
                        <Icon icon={"time"} className={"day-distance-icon"}/>
                            {this.state.distanceMatrix[fromEvent.id][toEvent.id]!.car.duration.text}
                    </span>
                    </div>
                );
            }
        }
        return <Spinner size={5}/>
    }

    getDistInOrder = () => {
        console.log("calling get dist on ", this.props.dayContent.name, " event count is: ", this.props.dayContent.events.length);
        const distPromises = [];
        for (let i = 0; i < this.props.events.length-1; i++) {
            const src = this.props.events[i];
            const dst = this.props.events[i+1];
            if (src && dst) {
                if (!this.state.distanceMatrix[src.id] || !this.state.distanceMatrix[src.id][dst.id]) {
                    distPromises.push(this.getDistance(src, dst));
                }
            }
        }
        Promise.all(distPromises);
    }

    getDistance = (fromEvent: IEventData, toEvent: IEventData) => {
        if (!fromEvent.location.lat || !toEvent.location.lat) {
            const cloneDistance = _.cloneDeep(this.state.distanceMatrix);
            _.set(cloneDistance, fromEvent.id.toString() + "." + toEvent.id.toString(), null);
            this.setState({distanceMatrix: cloneDistance});
        } else {
            // console.log("TO THE BACKEND");
            backendGetDistance({lat: fromEvent.location.lat, lng: fromEvent.location.long!}, {lat: toEvent.location.lat, lng: toEvent.location.long!})
                .then((distance) => {
                    const cloneDistance = _.cloneDeep(this.state.distanceMatrix);
                    _.set(cloneDistance, fromEvent.id.toString() + "." + toEvent.id.toString(), distance);
                    this.setState({distanceMatrix: cloneDistance});
                });
        }
    }

}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: IOwnProps): IDispatchProps => {
    return {
        setDayName: (name: string) => {
            dispatch(SET_DAY_TITLE(ownProps.dayIndex, name));
        },
    };
};
const mapStateToProps = (state: IGlobalState, ownProps: IOwnProps): IStateProps => {
    return {
        events: ownProps.dayContent.events.map((eventId) => state.currentBoard.eventData[eventId]),
    };
};

export const ConnectedDayCard = connect(mapStateToProps, mapDispatchToProps)(DayCard);