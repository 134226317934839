import {applyMiddleware, createStore, DeepPartial} from 'redux'
import thunkMiddleware from 'redux-thunk'

import rootReducer from './reducers'
import {IGlobalState, emptyTripData, ITrip} from "./GlobalState";
import {composeWithDevTools} from "redux-devtools-extension";
import { firestore, isFirebaseError } from '../util/firestore';
import {enableBatching} from "redux-batched-actions";

export async function configureStore(preloadedState: DeepPartial<IGlobalState>) {
    const middlewares = [thunkMiddleware];
    const middlewareEnhancer = applyMiddleware(...middlewares);
    // const enhancers = [middlewareEnhancer, monitorReducerEnhancer];
    const composedEnhancers = composeWithDevTools(middlewareEnhancer);
    const store = createStore(enableBatching(rootReducer), preloadedState, composedEnhancers);
    return store
}


// const round = (number: number) => Math.round(number * 100) / 100;

// const monitorReducerEnhancer = (createStore: StoreCreator) => (
//     reducer: Reducer,
//     initialState: IGlobalState,
//     enhancer: StoreEnhancer
// ) => {
//     const monitoredReducer = (state: IGlobalState, action: AllActionsType) => {
//         const start = performance.now();
//         const newState = reducer(state, action);
//         const end = performance.now();
//         const diff = round(end - start);
//
//         console.log('reducer process time:', diff);
//
//         return newState;
//     };
//
//     return createStore(monitoredReducer, initialState, enhancer)
// };

// const logger = (store: Store<IGlobalState, AllActionsType>) => (next: any)  => (action: AllActionsType) => {
//     console.group(action.type);
//     console.info('dispatching', action);
//     let result = next(action);
//     console.log('next state', store.getState());
//     console.groupEnd();
//     return result
// };
