import {IDistanceResponse, IEventData, IEventSource} from "../../types/types";
import {getNextId} from "../../redux/GlobalState";
import * as _ from "lodash";

const BACKEND_URL = process.env.REACT_APP_BACKEND;

export const backendParser = (url: string, bias: {lat: number | null, long: number | null}): Promise<IEventData[]> => {
    return post(BACKEND_URL + "/parse/generic", {url, bias})
        .then((response) => {
            // console.log("RESPONSE IS: ", response);
            if (response.type === "error") {
                throw new Error(response.error);
            } else {
                return response;
            }
        })
        .then((events) => events.map((event: IEventData) => {return augmentEvent(event)}));
}

export const post = (url = '', data = {}) => {
    // Default options are marked with *
    return fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, cors, *same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrer: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
        .then(response => response.json()); // parses JSON response into native JavaScript objects
}

const augmentEvent = (event: IEventData) => {
    let tags = event.tags;
    let name = event.name;
    let description = event.description;
    if (_.has(event, "location.googlePlaceDetails.types")) {
        tags = _.uniq(tags.concat(mapGoogleTags(event.location!.googlePlaceDetails!.types!)));
    }
    if (event.source === IEventSource.OTHER && _.has(event, "location.googlePlaceDetails.name")) {
        name = event.location!.googlePlaceDetails!.name!;
    }
    if (_.has(event, "location.googlePlaceDetails.rating")) {
        const rating = event.location!.googlePlaceDetails!.rating!;
        description = "Google Rating: " + rating + "/5\n" + description;
    }
    if (_.isEmpty(event.location.lat) && _.has(event, "location.googlePlaceDetails.location.lat")) {
        event.location.lat = event.location!.googlePlaceDetails!.location!.lat;
        event.location.long = event.location!.googlePlaceDetails!.location!.long;
    }
    return {
        id: getNextId(),
        ...event,
        tags,
        name,
        description,
    }
}

const mapGoogleTags = (tags: string[]) => {
    const newTag: string[] = [];
    tags.forEach((t) => {
        if (googleTagMap[t]) {
            newTag.push(googleTagMap[t])
        }
    });
    return newTag;
}

const googleTagMap: {[key: string]: string} = {
    "lodging": "Hotel",
    "food": "Food",
    "restaurant": "Food",
    "bar": "Food",
}

export const backendGetDistance = (from: {lat: number; lng: number;}, to: {lat: number; lng: number;}): Promise<IDistanceResponse | null> => {
    return post(BACKEND_URL + "/info/distance", {from, to})
        .then((response) => {
            // console.log("RESPONSE IS: ", response);
            if (response.type === "error") {
                throw new Error(response.error);
            } else {
                const answer = response.rows[0].elements[0];
                if (answer.status === "ZERO_RESULTS") {
                    return null;
                } else {
                    return {
                        car: {
                            distance: answer.distance,
                            duration: answer.duration,
                        }
                    };
                }
            }
        });
}