import * as React from "react";
import {Icon} from "@blueprintjs/core";

interface IProps {
    onClick?: () => void;
}
export class ArrowButton extends React.Component<IProps, never> {

    render() {
        return (
            <button className={"arrow-icon"}>
                <Icon icon={"arrow-right"} color={"white"} />
            </button>
        );
    }
}