import {createAction} from "typesafe-actions";
import { IStateParams } from "../GlobalState";
import {IGooglePlaceDetails} from "../../types/types";

export const SET_NEXT_ID = createAction("SET_NEXT_ID", (resolve) => {
    return (nextId: number) => resolve(nextId);
});

export const SET_TRIP_NAME = createAction("SET_TRIP_NAME", (resolve) => {
    return (tripName: string) => resolve(tripName);
});

export const SET_TRIP_LOCATION = createAction("SET_TRIP_LOCATION", (resolve) => {
    return (tripLocation: IGooglePlaceDetails) => resolve(tripLocation);
});

export const HYDRATE_PARAMS = createAction("HYDRATE_PARAMS", (resolve) => {
    return (newParams: IStateParams) => resolve(newParams);
});
