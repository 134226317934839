import {createAction} from "typesafe-actions";

export const ADD_TO_WISHLIST = createAction("ADD_TO_WISHLIST", (resolve) => {
    return (event_id: string) => resolve(event_id);
});

export const REMOVE_FROM_WISHLIST = createAction("REMOVE_FROM_WISHLIST", (resolve) => {
    return (event_id: string) => resolve(event_id);
});

export const REORDER_WISHLIST = createAction("REORDER_WISHLIST", (resolve) => {
    return (fromIdx: number, toIdx: number) => resolve({fromIdx, toIdx});
});

export const HYDRATE_WISHLIST = createAction("HYDRATE_WISHLIST", (resolve) => {
    return (wishlist: string[]) => resolve(wishlist);
});
