import * as React from "react";
import { IGlobalState, ITrip } from "../redux/GlobalState";
import { connect } from "react-redux";
import {Dialog, Icon, Intent, Menu, MenuDivider, MenuItem, Popover, Position, Spinner} from "@blueprintjs/core";
import {firestore} from "../util/firestore";
import {ConnectedLoginBox} from "./LoginBox";

interface IStateProps {
    isLoggedIn: boolean;
    user: any;
    authLoading: boolean;
}
type IProps = IStateProps;

interface IState {
    isLoading: boolean;
    loginDialog: boolean;
}

class UserAccount extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            isLoading: props.authLoading,
            loginDialog: false,
        }
    }
    componentWillReceiveProps(nextProps: IProps) {
        if (nextProps.authLoading !== this.props.authLoading) {
            this.setState({isLoading: nextProps.authLoading});
        }
    }

    render() {
        console.log("logged in user is: ", this.props.user);
        const profilePicture = this.props.user ? this.props.user.photoURL : "";
        return (
            <div className={"nav-user-account"}>
                {this.state.isLoading ? <Spinner size={30} /> :
                    this.props.isLoggedIn ?
                        <Popover minimal={true} content={this.userMenu()} position={Position.BOTTOM}>

                            <img className={"navbar-account"} src={profilePicture} />
                        </Popover>
                        :
                        <button className={"navbar-button"} onClick={this.login}> Login </button>
                }
                {this.renderSignIn()}
            </div>
        );
    }

    userMenu = () => {
        return (
            <Menu>
                <MenuItem text={"My Account"} onClick={() => window.location.href= "/account"}/>
                <MenuDivider />
                <MenuItem icon={"remove"} intent={Intent.DANGER} text={"Logout"} onClick={this.logout}/>
            </Menu>
        )
    }
    logout = () => {
        firestore.signOut();
    }

    login = () => {
        console.log("log ing user ");
        this.setState({loginDialog: true});
    }

    renderSignIn() {
        return (
            <Dialog
                isOpen={this.state.loginDialog}
                title={"Who should we keep this trip for?"}
                icon={"user"}
                canEscapeKeyClose={false}
                canOutsideClickClose={false}
                enforceFocus={true}
                style={{width: "95%", maxWidth: "500px", lineHeight: "18px"}}
                onClose={() => this.setState({loginDialog: false})}
            >
                <ConnectedLoginBox onLoginComplete={this.onLoginComplete} onLoginFailed={this.onLoginFailed}/>
            </Dialog>
        );
    }
    onLoginComplete = (user: firebase.auth.UserCredential) => {
        this.setState({loginDialog: false});
    }

    onLoginFailed = () => {
        console.log("login didnn't work from the save button");
    }

}

const mapStateToProps = (state: IGlobalState): IStateProps => {
    return {
        isLoggedIn: Boolean(state.session.loggedInUser),
        user: state.session.loggedInUser,
        authLoading: state.session.authLoading,
    };
};

export const ConnectedUserAccount = connect(mapStateToProps)(UserAccount);