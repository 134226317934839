import firebase from "firebase";
// import "firebase/app";
// import "firebase/auth";
// import "firebase/firestore";
import {ITrip, emptyTripData, IBoard, emptyBoardData, IBookPage, IHost} from "../redux/GlobalState";
import { FirebaseError } from 'firebase';
import {async} from "q";
import {IGooglePlaceDetails} from "../types/types";
import * as _ from "lodash";

export const isFirebaseError = (err: any): err is FirebaseError => {
    return err.code !== undefined && err.message !== undefined && err.name === "FirebaseError";
}

const TRIP_COLLECTION = "tripsv2";
const BOARDS_COLLECTION = "boards";
const BOOK_COLLECTION = "book";
const EVENTS_COLLECTION = "events";
const HOSTS_COLLECTION = "hosts";

const firebaseConfig = {
    apiKey: "AIzaSyAEWh0EvnhgBCiivklaekJ_3CLAJEA9SJ8",
    authDomain: "rahee-gcp.firebaseapp.com",
    databaseURL: "https://rahee-gcp.firebaseio.com",
    projectId: "rahee-gcp",
    storageBucket: "rahee-gcp.appspot.com",
    messagingSenderId: "815656345280",
    appId: "1:815656345280:web:8950d7e5842aa519add725"
};

class Firestore {
    private instance: firebase.app.App;
    public db: firebase.firestore.Firestore;
    public trips: firebase.firestore.CollectionReference;
    public boards: firebase.firestore.CollectionReference;
    public book: firebase.firestore.CollectionReference;
    public host: firebase.firestore.CollectionReference;
    public events: firebase.firestore.CollectionReference;
    public auth: firebase.auth.Auth;
    private googleProvider: firebase.auth.GoogleAuthProvider;
    private facebookProvider: firebase.auth.FacebookAuthProvider;

    constructor(config: {}){
        this.instance = firebase.initializeApp(config);
        this.db = this.instance.firestore();
        this.db.settings({});
        this.trips = this.db.collection(TRIP_COLLECTION);
        this.boards = this.db.collection(BOARDS_COLLECTION);
        this.book = this.db.collection(BOOK_COLLECTION);
        this.host = this.db.collection(HOSTS_COLLECTION);
        this.events = this.db.collection(EVENTS_COLLECTION);
        this.auth = this.instance.auth();
        this.googleProvider = new firebase.auth.GoogleAuthProvider();
        this.facebookProvider = new firebase.auth.FacebookAuthProvider();
    }

    // *** Auth API ***

    public createUser = (email: string, password: string) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    public signIn = (email: string, password: string) =>
        this.auth.signInWithEmailAndPassword(email, password);

    doSignInWithGoogle = () =>
        this.auth.signInWithPopup(this.googleProvider);

    doSignInWithFacebook = () =>
        this.auth.signInWithPopup(this.facebookProvider);

    public signOut = () => this.auth.signOut();

    public resetPassword = (email: string) => this.auth.sendPasswordResetEmail(email);

    public updatePassword = (password: string) => {
        if (this.auth.currentUser) {
            return this.auth.currentUser.updatePassword(password);
        }
    }

    public updateTrip = (trip: ITrip) => {
      console.log("HIIII", this.auth.currentUser, trip.firestoreId, trip);
      if (this.auth.currentUser) {
        if (this.auth.currentUser.email === trip.owner || !trip.owner) {
          if (this.auth.currentUser.email) { // null check to resolve types
            trip.owner = this.auth.currentUser.email;
          }
          return this.trips
            .doc(trip.firestoreId)
            .update(trip);
        } else {
          alert("You don't have permission to save this trip directly. You can clone a copy of this trip with the clone button");
        }
      } else {
        return Promise.resolve(false);
      }
    }

    public updateBoard = (board: IBoard) => {
        console.log("HIIII SAVING BOARD", this.auth.currentUser, board.firestoreId, board);
        const boardCopy = _.cloneDeep(board);
        if (this.auth.currentUser) {
            if (this.auth.currentUser.email === boardCopy.owner || !boardCopy.owner) {
                if (this.auth.currentUser.email) { // null check to resolve types
                    boardCopy.owner = this.auth.currentUser.email;
                }
                boardCopy.eventIDs = []; // empty event ids before saving (it's loaded on hydrate
                return this.boards
                    .doc(boardCopy.firestoreId)
                    .update(boardCopy);
            } else {
                alert("You don't have permission to save this board directly. You can clone a copy of this board with the clone button");
            }
        } else {
            return Promise.resolve(false);
        }
    }

    public cloneTrip = async (oldTrip: ITrip): Promise<string | null> => {
      const newOwner = this.auth.currentUser && this.auth.currentUser.email
        ? this.auth.currentUser.email
        : "";
      const newDocRef = this.trips.doc();
      const newTrip: ITrip = {
        firestoreId: newDocRef.id,
        owner: newOwner,
        eventData: oldTrip.eventData,
        wishlist: oldTrip.wishlist,
        params: oldTrip.params,
        days: oldTrip.days,
      };
      await newDocRef.set(newTrip);
      return newTrip.firestoreId;
    }

    public cloneBoard = async (oldBoard: IBoard): Promise<string | null> => {
        const newOwner = this.auth.currentUser && this.auth.currentUser.email
            ? this.auth.currentUser.email
            : "";
        const newDocRef = this.boards.doc();
        const newBoard: IBoard = {
            firestoreId: newDocRef.id,
            owner: newOwner,
            eventData: oldBoard.eventData,
            params: oldBoard.params,
            eventIDs: oldBoard.eventIDs,
            location: oldBoard.location,
            boardName: oldBoard.boardName,
        };
        await newDocRef.set(newBoard);
        return newBoard.firestoreId;
    }

    public cloneTrip2 = async (oldTrip: ITrip): Promise<ITrip | null> => {
      const newOwner = this.auth.currentUser && this.auth.currentUser.email
        ? this.auth.currentUser.email
        : "";
      const newDocRef = this.trips.doc();
      const newTrip: ITrip = {
        firestoreId: newDocRef.id,
        owner: newOwner,
        eventData: oldTrip.eventData,
        wishlist: oldTrip.wishlist,
        params: oldTrip.params,
        days: oldTrip.days,
      };
      await newDocRef.set(newTrip);
      return newTrip;
    }


    public loadTrip = async (docId: string): Promise<{err: string, trip: ITrip | null}> => {
        let doc;
        try {
            doc = await this.trips.doc(docId).get();
            console.log("trip back is: ", {doc, docId});
        } catch (reason) {
            return {
              err: `${reason}`,
              trip: null,
            };
        }
        if (doc) {
            if (doc.data()) {
              return {err: "", trip: doc.data() as ITrip}
            } else {
              return {err: "DocumentUndefined", trip: null}
            }
        }
        return {
          err: "Unknown",
          trip: null,
        };
    }

    public loadBoard = async (docId: string): Promise<{err: string, board: IBoard | null}> => {
        let doc;
        try {
            doc = await this.boards.doc(docId).get();
        } catch (reason) {
            return {
                err: `${reason}`,
                board: null,
            };
        }
        if (doc) {
            if (doc.data()) {
                return {err: "", board: doc.data() as IBoard}
            } else {
                return {err: "DocumentUndefined", board: null}
            }
        }
        return {
            err: "Unknown",
            board: null,
        };
    }

    public loadBookPage = async (docId: string): Promise<{err: string, bookPage: IBookPage | null}> => {
        let doc;
        try {
            doc = await this.book.doc(docId).get();
        } catch (reason) {
            return {
                err: `${reason}`,
                bookPage: null,
            };
        }
        if (doc) {
            if (doc.data()) {
                return {err: "", bookPage: doc.data() as IBookPage}
            } else {
                return {err: "DocumentUndefined", bookPage: null}
            }
        }
        return {
            err: "Unknown",
            bookPage: null,
        };
    }

    public loadHostPage = async (docId: string): Promise<{err: string, hostData: IHost | null}> => {
        let doc;
        try {
            doc = await this.host.doc(docId).get();
        } catch (reason) {
            return {
                err: `${reason}`,
                hostData: null,
            };
        }
        if (doc) {
            if (doc.data()) {
                return {err: "", hostData: doc.data() as IHost}
            } else {
                return {err: "DocumentUndefined", hostData: null}
            }
        }
        return {
            err: "Unknown",
            hostData: null,
        };
    }

    public newTrip = async (location: IGooglePlaceDetails): Promise<ITrip> => {
        const newOwner = this.auth.currentUser && this.auth.currentUser.email
            ? this.auth.currentUser.email
            : "";
        const tripName = "My Trip to " + location.name;
        const emptyTrip: ITrip = emptyTripData();
        emptyTrip.params.tripLocation = location;
        emptyTrip.params.tripName = tripName;
        const newDocRef = this.trips.doc();
        const newTrip: ITrip = {
            ...emptyTrip,
            firestoreId: newDocRef.id,
            owner: newOwner,
        };
        console.log("new trip with info: ", newTrip);
        await newDocRef.set(newTrip);
        return newTrip;
    }

    public newBoard = async (location: IGooglePlaceDetails): Promise<IBoard> => {
        const owner = this.auth.currentUser && this.auth.currentUser.email
            ? this.auth.currentUser.email
            : "";
        const boardName = "Inspiration from " + location.name;
        const emptyBoard: IBoard = emptyBoardData();
        emptyBoard.location = location;
        emptyBoard.boardName = boardName;
        const newDocRef = this.boards.doc();
        const newBoard: IBoard = {
            ...emptyBoard,
            firestoreId: newDocRef.id,
            owner,
        };
        console.log("new board with info: ", newBoard);
        await newDocRef.set(newBoard);
        return newBoard;
    }


}

export const firestore = new Firestore(firebaseConfig);
