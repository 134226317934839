import {createAction} from "typesafe-actions";
import { ISelectedCard } from "../GlobalState";

export const UPDATE_USER = createAction("UPDATE_USER", (resolve) => {
    return (user: firebase.User) => resolve(user);
});

export const SET_IS_TRIP = createAction("SET_IS_TRIP", (resolve) => {
    return (isTrip: boolean) => resolve(isTrip);
});

export const SET_SELECTED_CARD = createAction("SET_SELECTED_CARD", (resolve) => {
    return (selectedCard: ISelectedCard) => resolve(selectedCard);
});