import * as React from "react";
import {Intent, Tag} from "@blueprintjs/core";
import "../styles/EventCard.css";
import * as _ from "lodash";
import "../styles/TagList.css";

interface IProps {
    tags: string[];
    selected: string[];
    interactive: boolean;
    updateSelectedTags?: (tags: string[]) => void;
}
export class TagList extends React.Component<IProps, never> {

    render() {
        return (
                <div className={"tag-list"}>
                    {this.props.tags.map((tag, i) => <Tag
                        key={i}
                        className={"tag " + tag}
                        active={this.props.interactive ? this.isSelected(tag) : true}
                        onClick={(e) => this.onTagClick(tag)}
                        interactive={this.props.interactive}
                        round={true}
                        large={false}
                    >
                        {tag}
                        </Tag>)}
                </div>
        )
    }

    isSelected = (tag: string) => {
        return this.props.selected.indexOf(tag) !== -1;
    }

    onTagClick = (tag: string) => {
        if (this.props.updateSelectedTags) {
            const newSelected = _.cloneDeep(this.props.selected);
            const tagIdx = newSelected.indexOf(tag);
            if (tagIdx !== -1) {
                newSelected.splice(tagIdx, 1);
            } else {
                newSelected.push(tag);
            }
            this.props.updateSelectedTags(newSelected);
        }
    }
}
