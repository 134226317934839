import * as React from "react";
import {Button, ButtonGroup, Card, Dialog, H3, H4, Icon, Intent, MenuItem, Spinner} from "@blueprintjs/core";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import * as _ from "lodash";
import {
    getPlaceDetailsFromPlaceId,
    getPublicBoards,
    getPublicTrips,
    IPublicTrip,
    suggestPlaceFromQuery
} from "../api/server";
import {Suggest} from "@blueprintjs/select";
import {areLocationsEqual, IGooglePlaceSuggestion, locationSelectProps} from "../util/locations";
import {IGooglePlaceDetails} from "../types/types";
import {SET_TRIP_LOCATION} from "../redux/actions/paramsActions";
// @ts-ignore
import * as ReactRotatingText from "react-rotating-text";
import {ConnectedUserAccount} from "../components/UserAccount";
import {firestore} from "../util/firestore";
import {IBoardLandingPreview} from "../redux/GlobalState";
import Gallery from "react-photo-gallery";

interface IDispatchProps {
    setTripLocation: (tripLocation: IGooglePlaceDetails) => void;
}

interface IState {
    location: IGooglePlaceDetails | null;
    goToTrip: string;
    boards: IBoardLandingPreview[];
    loading: boolean;
    validLocations: IGooglePlaceSuggestion[];
    locationQuery: string;
    moveToApp: boolean;
    boardID: string;
}

const LocationSuggest = Suggest.ofType<IGooglePlaceSuggestion>();


export class LandingScreen extends React.Component<IDispatchProps, IState> {
    constructor(props: IDispatchProps) {
        super(props);
        this.state = {
            location: null,
            goToTrip: "",
            boards: [],
            loading: false,
            validLocations: [],
            locationQuery: "",
            moveToApp: false,
            boardID: "",
        }
    }

    async componentDidMount() {
        getPublicBoards()
            .then((boards) => {
                console.log("boards list: ", boards);
                if (!_.isEmpty(boards)) {
                    this.setState({boards});
                }
            });

    }

    render() {
        return (
            <div className={"landing"}>
                <div className={"landing-header"}>
                    <div className={"landing-info"}>
                        <div className={"landing-title"}>
                            <img width={190} src={"/images/logo.png"} />
                        </div>
                        <h1 className={"landing-tagline"}>
                            A travel planner that lets you do the fun part
                        </h1>
                    </div>
                    <div>
                        <ConnectedUserAccount />
                    </div>
                </div>
                <div className={"landing-main"}>
                    <div className={"landing-left"}>
                        <div className={"landing-description"}>
                            We parse the travel recommendations you get from&nbsp;
                            <div className={"landing-rotate"}>
                                <ReactRotatingText items={['Instagram', 'Trip Advisor', 'Eater', 'Yelp', 'Google Maps']} />
                            </div>
                        </div>
                        <div className={"landing-location"}>
                            <div className={"inspiration-link"}>
                                <ButtonGroup>
                                    <LocationSuggest
                                        {...locationSelectProps}
                                        inputValueRenderer={this.renderInputValue}
                                        itemsEqual={areLocationsEqual}
                                        // we may customize the default filmSelectProps.items by
                                        // adding newly created items to the list, so pass our own.
                                        items={this.state.validLocations}
                                        noResults={this.noResultRender()}
                                        onItemSelect={this.handleValueChange}
                                        popoverProps={{ minimal: true, fill: true}}
                                        query={this.state.locationQuery}
                                        onQueryChange={this.onQueryChange}
                                        className={"dropdown-options"}
                                        inputProps={{leftIcon: "search", intent: Intent.PRIMARY, placeholder: "Where do you want to go?", className: "landing-location-input"}}
                                    />
                                </ButtonGroup>
                            </div>
                        </div>
                    </div>
                    <div className={"landing-right"}>
                        <Card elevation={3} className={"landing-howto"}>
                                <div className={"howto-title"}> The Fastest Planning Tool </div>
                                <div className={"landing-step"}> <Icon color={"#5DA0C9"} iconSize={35} icon={"link"}/> Create an event using our link extractor </div>
                                <div className={"landing-step"}> <Icon color={"#5DA0C9"} iconSize={35} icon={"path-search"}/> Visualize that information on a map and make decisions </div>
                                <div className={"landing-step"}> <Icon color={"#5DA0C9"} iconSize={35} icon={"exchange"}/> Drag and drop event cards to create your travel plan </div>
                        </Card>
                    </div>
                </div>
                <div className={"trips-col"}>
                    <div className={"trips-title"}> Get some Inspiration </div>
                    <div className={"landing-main"}>
                        <div className={"all-boards"}>
                            {this.state.boards.map((board, i) => this.renderBoard(board, i))}
                        </div>
                        {_.isEmpty(this.state.boards) ? <Spinner /> : null}
                    </div>
                </div>
                {this.getPopup()}
            </div>
        );
    }

    renderBoard(board: IBoardLandingPreview, key: number) {
        if (board.images && board.images.length > 0) {
            return (
                <div key={key} className={"board-section"} onClick={() => {window.location.href = "/trip/" + board.firestoreId}}>
                    <H3> {board.boardName} </H3>
                    <div className={"landing-board-content"}>
                        {this.renderImages(board.images)}
                    </div>
                    <div className={"board-hover"}>
                        Click to explore this board
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
    renderImages = (images: string[]) => {
        const photos = _.uniq(images).slice(0,4).map((im) => {
            return {
                src: im,
                width: 1,
                height: 1,
            }
        });
        return (
            <div className={"trip-photos"}>
                <Gallery targetRowHeight={3} photos={photos} />
            </div>
        );
    }

    noResultRender() {
        if (this.state.locationQuery.length > 3) {
            return (<MenuItem className={"location-name-entry"} disabled={true} text="This place sounds awesome, but we don't recognize it" />);
        } else {
            return null;
        }
    }

    renderPreviewBox(trip: IPublicTrip, key: number) {
        return (
            <div key={key} className={"landing-preview-box"}>
                <div className={"box-row"}>
                    <div className={"box-col"}>
                        <div className={"profile-pic"}> <img src={"https://content-static.upwork.com/uploads/2014/10/01073427/profilephoto1.jpg"}/> </div>
                        <div className={"box-owner"}> Saba Y </div>
                    </div>
                    <div className={"box-details"}>
                        <div className={"box-location"}> {trip.location} </div>
                        <div className={"box-row"}>
                            <div className={"box-number"}>
                                <div className={"box-circle"}> {trip.dayCount} </div>
                                <div> days </div>
                            </div>
                            <div className={"box-number"}>
                                <div className={"box-circle"}> {trip.eventCount} </div>
                                <div> events </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"box-btn"} onClick={() => {
                    this.setState({goToTrip: trip.tripID});
                }}> Check it out </div>
            </div>
        );
    }

    onQueryChange = (query: string) => {
        console.log("new query", query);
        this.setState({locationQuery: query});
        if (query.length > 2) {
            suggestPlaceFromQuery(query)
                .then((suggestions) => {
                    this.setState({validLocations: suggestions})
                });
        } else if (query.length === 0 ) {
            this.setState({validLocations: []})
        }
    }
    newTrip = () => {
        // create new trip with this location
        console.log("create a new trip to: ", this.state.location);
        if (this.state.location) {
            this.setState({loading: true});
            this.props.setTripLocation(this.state.location);
            firestore.newTrip(this.state.location)
                .then((newTrip) => {
                    this.setState({goToTrip: newTrip.firestoreId});
                    window.location.href = "/trip/" + newTrip.firestoreId;
                });
        }
    }

    newBoard = () => {
        // create new trip with this location
        console.log("create a new board to: ", this.state.location);
        if (this.state.location) {
            this.setState({loading: true});
            this.props.setTripLocation(this.state.location);
            firestore.newBoard(this.state.location)
                .then((newBoard) => {
                    this.setState({boardID: newBoard.firestoreId});
                    window.location.href = "/board/" + newBoard.firestoreId;
                });
        }
    }

    getPopup() {
        return (
            <Dialog
                isOpen={!_.isEmpty(this.state.location)}
                title={"New Trip"}
                icon={"airplane"}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                enforceFocus={true}
                style={{width: "95%", maxWidth: "500px", lineHeight: "18px"}}
                onClose={() => this.setState({location: null, locationQuery: "", validLocations: []})}
            >
                <div className={"choose-type"}>
                    <div className={"location-name"}> {this.state.location ? this.state.location.name : ""} </div>
                    <div className={"options"}>
                        {/*<div className={"section"}>*/}
                            {/*<img src={"images/Map_light.svg"}/>*/}
                            {/*Create a Board if you are just trying to collect recommendations on a specific place but you*/}
                            {/*are not yet ready to plan a trip there. A Board will hold your recommendations and*/}
                            {/*when you want to go on a trip, you can convert your Board to a Trip with one click.*/}
                            {/*<Button className={"go-btn"} intent={Intent.PRIMARY} disabled={_.isEmpty(this.state.location)} onClick={this.newBoard}> Board </Button>*/}

                        {/*</div>*/}
                        {/*<div className={"vl"} />*/}
                        <div className={"section"}>
                            <img src={"images/adventure.svg"}/>
                            Trips let you organize your
                            recommendations into a day by day plan. Get started and collect ideas for your next trip.
                            <Button className={"go-btn"} intent={Intent.PRIMARY} disabled={_.isEmpty(this.state.location)} onClick={this.newTrip}> Create Trip </Button>

                        </div>
                    </div>
                </div>
            </Dialog>
        );
    }

    private renderInputValue = (loc: IGooglePlaceSuggestion) => loc.description;

    private handleValueChange = (loc: IGooglePlaceSuggestion) => {
        console.log("VALUE CHANGE TO: ", loc);
        this.setState({locationQuery: loc.description});
        getPlaceDetailsFromPlaceId(loc.place_id)
            .then((placeDetails) => {
                this.setState({location: placeDetails});
            });
    };

}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
    return {
        setTripLocation: (tripLocation: IGooglePlaceDetails) => {
            dispatch(SET_TRIP_LOCATION(tripLocation));
        }
    };
};

export const ConnectedLandingScreen = connect(null, mapDispatchToProps)(LandingScreen);