import * as React from "react";
import {EVENT_DURATION, IEventData, IEventSource, ILocationData} from "../types/types";
import {ChangeEventHandler, ClipboardEventHandler} from "react";
import {
    Button,
    Card,
    EditableText,
    H4,
    Icon,
    Intent,
    Spinner,
    ButtonGroup,
    Drawer,
    NonIdealState, Tag, Dialog, Toaster, Position
} from "@blueprintjs/core";
import * as _ from "lodash";
import {TagList} from "./TagList";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {EventActionsType} from "../redux/reducers/eventsReducer";
import {ADD_EVENT} from "../redux/actions/eventActions";
import {parseURL} from "../util/parsers/mainParser";
import {ConnectedPendingEvent} from "./PendingEvent";
import update from 'immutability-helper';
import {EditableEvent} from "./EditableEvent";
import {getNextId, IGlobalState} from "../redux/GlobalState";
import {addEventToTrip, newEventToWishlist} from "../redux/actions/batchedActions";
import {KeyboardEvent} from "react";
import {getActiveTags} from "../redux/selectors";
import {IGooglePlaceSuggestion} from "../util/locations";
import {getPlaceDetailsFromPlaceId, suggestEventFromString} from "../api/server";
import {addEvent} from "../redux/actions/boardActions";
import {ConnectedEditFirebaseEvent} from "./EditFirebaseEvent";

interface IState {
    input: string;
    fetching: boolean;
    eventData: IEventData[] | null;
    editedEventData: IEventData| null;
    isDrawerOpen: boolean;
    eventSuggestions: IGooglePlaceSuggestion[];
}
interface IStateProps {
    locationBias: {
        lat: number;
        long: number;
    },
    boardID: string;
    tripID: string;
}
interface IDispatchProps {
}
interface IOwnProps {
    isTrip: boolean;
}
type IProps = IDispatchProps & IStateProps & IOwnProps;
class NewEvent extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            input: "",
            fetching: false,
            eventData: null,
            editedEventData: null,
            isDrawerOpen: false,
            eventSuggestions: [],
        };
    }

    render() {
        return (
            <div className={"new-event-card"}>
                <div className={"bp3-input-group bp3-large search-input inspiration-link"}>
                    <span className={"bp3-icon bp3-icon-search"}></span>
                    <input type="text" className="bp3-input less-round" placeholder="Type the name of a place, or paste a link" value={this.state.input} onKeyUp={this.inputKeyUp} onChange={this.onInputChange} onPaste={this.onPaste}/>
                    {this.renderDropdown()}
                    {_.isEmpty(this.state.input) ?
                        <Button className={"empty-event-btn"} large={false} intent={Intent.PRIMARY} onClick={this.createBlankEvent}> + </Button>
                        :
                        <Button className={"btn-round"} minimal intent={Intent.PRIMARY} icon={"arrow-right"} disabled={_.isEmpty(this.state.input)} loading={this.state.fetching} onClick={this.submit}/>
                    }
                </div>
                {/*<div className="vl"></div>*/}
                {/*<Tag round interactive className={"empty-event-btn"} onClick={this.createBlankEvent}> Blank Card</Tag>*/}
                {this.renderCard()}
            </div>
        )
    }

    renderCard = () => {
        if (_.isArray(this.state.eventData) && this.state.eventData.length > 1) {
            return (
                <ConnectedEditFirebaseEvent onCloseEdit={() => this.setState({eventData: []})} isTrip={this.props.isTrip} eventData={this.state.eventData} />
            );
        } else if (this.state.editedEventData) {
            return (
                <ConnectedEditFirebaseEvent onCloseEdit={() => this.setState({editedEventData: null})} isTrip={this.props.isTrip} eventData={[this.state.editedEventData]} />
            )
        } else {
            return null;
        }
    }

    renderDropdown = () => {
        if (_.isEmpty(this.state.eventSuggestions)) {
            return null;
        }
        return (
            <div className={"new-event-suggestions"}>
                {this.state.eventSuggestions.map((suggestion, i) => {
                    return (
                        <div className={"suggestion-entry"} key={i} onClick={() => this.useSuggestion(suggestion)}>
                            {suggestion.description}
                        </div>
                    )
                })}
            </div>
        )
    }

    useSuggestion = (suggestion: IGooglePlaceSuggestion) => {
        this.setState({fetching: true, input: suggestion.description, eventSuggestions: []});
        getPlaceDetailsFromPlaceId(suggestion.place_id)
            .then((details) => {
                const imageLink = !_.isEmpty(details.photos) ?
                    details.photos![0].photo_reference : "";

                this.setState({
                   editedEventData: {
                       id: "",
                       name: details.name ? details.name : "",
                       image: "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference= "
                       + imageLink + "&key=AIzaSyALPi247glKjHskrtaDIwWgnZhfYPr5sIk",
                       description: suggestion.description,
                       source: IEventSource.SEARCH,
                       source_url: "",
                       location: {
                           lat: details.location ? details.location.lat : undefined,
                           long: details.location ? details.location.long: undefined,
                           googlePlaceDetails: details,
                       },
                       tags: [],
                       duration: EVENT_DURATION.NONE,
                   },
                    input: "",
                    fetching: false,
               })
            });

    }

    onPaste: ClipboardEventHandler = (event) => {
        if (event.clipboardData) {
            event.stopPropagation();
            event.preventDefault();
            console.log("PASTED: ", event);
            const link = event.clipboardData.getData("Text").toString();
            console.log("PASTED: ", link);
            this.setState({input: link}, () => {
                console.log("NEW STATE: ", this.state.input);
                this.submit();
            });
        }
    }

    createBlankEvent = () => {
        this.setState({editedEventData: {
                id: "",
                name: "",
                image: "/images/add_pic.png",
                description: "",
                source: IEventSource.MANUAL,
                source_url: "",
                location: {},
                tags: [],
                duration: EVENT_DURATION.NONE,
            }});
    }

    inputKeyUp = (e: KeyboardEvent) => {
        if (e.keyCode === 13) {
            if (!_.isEmpty(this.state.input)) {
                this.submit();
            }
        }
    }

    submit = () => {
        console.log("submitting link: ", this.state.input);
        this.setState({fetching: true});
        parseURL(this.state.input, this.props.locationBias).then((answer) => {
            console.log("event got back is: ", answer);
            this.setState({eventData: answer, input: ""});
            if (answer.length === 1) {
                this.setState({fetching: false, editedEventData: answer[0]});
            } else if (answer.length > 1) {
                this.setState({fetching: false, isDrawerOpen: true});
            } else {
                this.setState({fetching: false});
                const AppToaster = Toaster.create({
                    position: Position.TOP,
                });
                AppToaster.show({ intent: Intent.WARNING, message: "Sorry, we couldn't extract any content from this page. We will work on it!" });
            }
        })
            .catch((e) => {
                const AppToaster = Toaster.create({
                    position: Position.TOP,
                });
                AppToaster.show({ intent: Intent.DANGER, message: "Oops. Something went wrong. Please try a different link." });
                this.setState({fetching: false});
            });
    }

    onInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        console.log("state is set to: ", event.target.value);
        this.setState({input: event.target.value});
        if (_.isEmpty(event.target.value)) {
            this.setState({eventSuggestions: []});
        } else {
            suggestEventFromString(this.state.input, {lat: this.props.locationBias.lat, long: this.props.locationBias.long})
                .then((suggestions) => {
                    console.log("google suggests the following places");
                    console.log(suggestions);
                    this.setState({eventSuggestions: suggestions});
                })
        }
    }
}

const mapDispatchToProps = (dispatch: any): IDispatchProps => { // TODO: fix dispatch type
    return {
    };
};
const mapStateToProps = (state: IGlobalState, ownProps: IOwnProps): IStateProps => {
    const tripLocation = state.currentTrip.params.tripLocation ? state.currentTrip.params.tripLocation.location! : {lat: null, long: null};
    const boardLocation = state.currentBoard.location ? state.currentBoard.location.location! : {lat: null, long: null};
    return {
        locationBias: ownProps.isTrip ? {lat: tripLocation.lat!, long: tripLocation.long!} :  {lat: boardLocation.lat!, long: boardLocation.long!},
        boardID: state.currentBoard.firestoreId,
        tripID: state.currentTrip.firestoreId,
    };
};
export const ConnectedNewEvent = connect(
    mapStateToProps,
    mapDispatchToProps,
)(NewEvent);