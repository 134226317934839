import * as React from "react";
import {IEventData} from "../types/types";
import {
    Drawer,
    Dialog
} from "@blueprintjs/core";
import {connect} from "react-redux";
import update from 'immutability-helper';
import {EditableEvent} from "./EditableEvent";
import {IGlobalState} from "../redux/GlobalState";
import {addEventToTrip, updateEventInFirestore} from "../redux/actions/batchedActions";
import {addEvent} from "../redux/actions/boardActions";
import * as _ from "lodash";

interface IState {
    isDrawerOpen: boolean;
    editedEventDataList: IEventData[];
}
interface IStateProps {
    boardID: string;
    tripID: string;
}
interface IDispatchProps {
    addEventToBoard(boardID: string, data: IEventData): void;
    addEventToTrip(tripID: string, data: IEventData): void;
    updateEventInFirestore: (eventID: string, event: IEventData) => void;
}
interface IOwnProps {
    isTrip: boolean;
    eventData: IEventData[] | null;
    onCloseEdit: () => void;
    eventID?: string;
}
type IProps = IDispatchProps & IStateProps & IOwnProps;
class EditFirebaseEvent extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            isDrawerOpen: !!(props.eventData && props.eventData.length > 1),
            editedEventDataList: props.eventData ? props.eventData : [],
        };
    }

    componentDidUpdate(prevProps: IProps) {
        if (this.props !== prevProps) {
            this.setState({
                isDrawerOpen: !!(this.props.eventData && this.props.eventData.length > 1),
                editedEventDataList: this.props.eventData ? this.props.eventData : [],
            });
        }
    }

    render = () => {
        if (this.state.editedEventDataList.length > 1) {
            return (
                <Drawer
                    icon="info-sign"
                    onClose={this.handleDrawerClose}
                    title={"Event We Found (" + this.state.editedEventDataList.length + ")"}
                    size={"70%"}
                    isOpen={this.state.isDrawerOpen}
                    canOutsideClickClose={false}
                >   <div className={"new-event-list"}>
                    {this.state.editedEventDataList.map((event, i) => {
                        return (
                            <div key={i}>
                                <EditableEvent inList key={event.id}  updateEvent={this.updateEvent} onCancel={() => this.removePendingEvent(i)} eventData={event} />
                                <hr />
                            </div>
                        )
                    })}
                </div>
                </Drawer>
            );
        } else if (this.state.editedEventDataList.length === 1) {
            return (
                <Dialog
                    isOpen={true}
                    canEscapeKeyClose={false}
                    canOutsideClickClose={false}
                    enforceFocus={true}
                    style={{width: "95%", maxWidth: "750px"}}
                >
                    <EditableEvent inList={false} onCancel={this.onCloseEditDialog} updateEvent={this.updateEvent} eventData={this.state.editedEventDataList[0]}/>
                </Dialog>
            )
        } else {
            return null;
        }
    }


    handleDrawerClose = () => {
        this.setState({isDrawerOpen: false});
        this.props.onCloseEdit();
    }

    handleDrawerOpen = () => {
        this.setState({isDrawerOpen: true});
    }

    onCloseEditDialog = () => {
        this.setState({editedEventDataList: []});
        this.props.onCloseEdit();
    }

    updateEvent = (event: IEventData) => {
        if (!_.isEmpty(this.props.eventID) && this.props.eventData && this.props.eventData.length === 1) {
            this.props.updateEventInFirestore(this.props.eventID!, event);
        } else {
            console.log("adding 1");
            if (this.props.isTrip) {
                this.props.addEventToTrip(this.props.tripID, event);
            } else {
                this.props.addEventToBoard(this.props.boardID, event);
            }
        }
        // clear the editedEventData state
        this.setState({editedEventDataList: []});
        this.props.onCloseEdit();

    }

    removePendingEvent = (i: number) => {
        const newEventList = update(this.state.editedEventDataList, {$splice: [[i, 1]]});
        this.setState({editedEventDataList: newEventList});
    }
}

const mapDispatchToProps = (dispatch: any): IDispatchProps => { // TODO: fix dispatch type
    return {
        addEventToTrip: (tripID: string, data: IEventData) => {
            dispatch(addEventToTrip(tripID, data));
        },
        addEventToBoard: (boardID: string, data: IEventData) => {
            console.log("calling this area here...");
            dispatch(addEvent(boardID, data));
        },
        updateEventInFirestore: (eventID: string, event: IEventData) => {
            dispatch(updateEventInFirestore(eventID, event));
    }
    };
};
const mapStateToProps = (state: IGlobalState, ownProps: IOwnProps): IStateProps => {
    return {
        boardID: state.currentBoard.firestoreId,
        tripID: state.currentTrip.firestoreId,
    };
};
export const ConnectedEditFirebaseEvent = connect(
    mapStateToProps,
    mapDispatchToProps,
)(EditFirebaseEvent);