import * as React from "react";
import ReactDOM from "react-dom";
import {IEventData} from "../types/types";
import {
    Card,
    Icon,
    Intent,
    Menu, MenuDivider, MenuItem,
    Popover,
    Position,
    Toaster,
    IToaster,
} from "@blueprintjs/core";
import "../styles/EventCard.css";
import {TagList} from "./TagList";
import {IGlobalState, emptyTripData, ISelectedCard} from "../redux/GlobalState";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {ExpandableText} from "./ExpandableText";
import {ArrowButton} from "./ArrowButton";
import {moveEventFromDayToWishlist, moveEventToDay} from "../redux/actions/batchedActions";
import { SET_SELECTED_CARD } from "../redux/actions/sessionActions";
import {CheckButton} from "./CheckButton";
import * as _ from "lodash";

interface IOwnProps {
    event: IEventData;
    isHoverCard: Boolean;
    eventID: string;
}
interface IStateProps {
    moveToList: {name: string; index: number}[];
    eventIsinWishlist: boolean;
    selectedCard: ISelectedCard;
    assignedDay: number | null;
}
interface IDispatchProps {
    moveEvent(id: number): void;
    moveBackToWishlist(): void;
    selectCard(id: string): void;
}
type IProps = IOwnProps & IStateProps & IDispatchProps;

interface IState {
    cardClass: string;
}

class EventCard extends React.Component<IProps, IState> {
    static defaultProps = {
        isHoverCard: false,
    }

    private toastRef: IToaster | null = null

    constructor(props: IProps) {
        super(props);
        this.state = {
            cardClass: "event-card",
        }
    }

    selectCard = () => {
        if (this.props.selectedCard.id === this.props.event.id) {
            this.setState({cardClass: "event-card"});
            this.props.selectCard("");
        } else {
            this.setState({cardClass: "event-card-focus"});
            this.props.selectCard(this.props.event.id);
            if ((!this.props.event.location.lat || !this.props.event.location.long) && this.toastRef) {
                this.toastRef.show({message: "Event does not have a location", intent: Intent.DANGER});
            }
        }
    }

    public render() {
        const locationName = this.props.event.location.name ? this.props.event.location.name : this.props.event.location.googlePlaceDetails ? this.props.event.location.googlePlaceDetails.address : "";
        let cardClass = this.props.event.id === this.props.selectedCard.id
            ? "event-card-focus"
            : "event-card";
        cardClass = this.props.isHoverCard ? cardClass + " " + "hover-card" : cardClass;
        return (
            <div>
                <Toaster position={Position.TOP} canEscapeKeyClear={true} ref={(ref) => {this.toastRef = ref}}/>
                <Card
                    className={cardClass}
                    interactive={true} 
                    elevation={1} 
                    onClick={this.selectCard}
                    ref={() => {
                        if (this.props.selectedCard.source === "map" && this.props.selectedCard.id === this.props.eventID) {
                            const node = ReactDOM.findDOMNode(this);
                            if (node && !this.props.isHoverCard && node instanceof Element) {
                                node.scrollIntoView();
                                const scrolledY = window.scrollY;
                                if (scrolledY) {
                                    window.scroll(0, scrolledY - 100);
                                }
                            }
                        }
                    }}
                >
                    <div className={"event-card-top"}>
                        <div className={"event-title"}>
                            {this.props.event.name}
                        </div>
                        <div className={"event-source"}>
                            <a href={this.props.event.source_url} rel="noopener noreferrer" target="_blank"> {this.props.event.source} </a>
                        </div>
                    </div>
                    <div className={"event-card-bottom"}>
                        <div className={"event-image-wrapper"}>
                            <img className={"event-image"} src={this.props.event.image}/>
                        </div>
                        <div className={"event-body"}>
                            <ExpandableText className={"event-details"} lineLimit={3} text={this.props.event.description} />
                            <hr className={"event-divider"}/>
                            <div className={"event-tags"}>
                                {/*<div className={"event-duration-tag"}>*/}
                                    {/*/!*<object type="image/svg+xml" data="icons/time-icon.svg" className="event-icon" />*!/*/}
                                    {/*<Tag*/}
                                        {/*className={"tag"}*/}
                                        {/*interactive={false}*/}
                                        {/*round={true}*/}
                                        {/*intent={Intent.WARNING}*/}
                                    {/*>*/}
                                        {/*{this.props.event.duration}*/}
                                    {/*</Tag>*/}
                                {/*</div>*/}
                                <div className={"event-taglist"}>
                                    {/*<object type="image/svg+xml" data="icons/globe.svg" className="event-icon" />*/}
                                    <TagList selected={[]} tags={this.props.event.tags} interactive={false} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"event-footer"}>
                        <div className={"event-location"}>
                            <Icon iconSize={11} icon={"map-marker"} className={"event-loc-pin"}/>
                            <div className={"event-loc-name"}>{locationName}</div>
                        </div>
                        <Popover position={Position.RIGHT} content={this.renderMoveToMenu()} target={this.props.eventIsinWishlist ? <ArrowButton /> : <CheckButton /> } />
                    </div>
                </Card>
            </div>
        )
    }

    public renderMoveToMenu() {
        return (
            <Menu>
                {this.props.eventIsinWishlist ? null : <MenuItem text={"Back To Wishlist"} icon={"arrow-left"} onClick={this.props.moveBackToWishlist}/>}
                {this.props.eventIsinWishlist ? null : <MenuDivider/>}
                {this.props.moveToList.map((item, i) => {
                    const intent = this.props.assignedDay === item.index ? Intent.SUCCESS : Intent.NONE;
                    return (<Menu.Item intent={intent} key={i} onClick={() => this.handleMove(item.index)} text={item.name} />);
                })}
            </Menu>
        );
    }

    public handleMove(id: number) {
        console.log("moving to... ", id);
        this.props.moveEvent(id);
    }

}

const mapStateToProps = (state: IGlobalState, ownProps: IOwnProps): IStateProps => {
    const potentialDayIdx = _.findIndex(state.currentTrip.days, (day) => day.events.includes(ownProps.event.id));
    return {
        moveToList: state.currentTrip.days.map((day, i) => {return {name: day.name, index: i}}),
        eventIsinWishlist: state.currentTrip.wishlist.includes(ownProps.event.id),
        selectedCard: state.session.selectedCard,
        assignedDay: potentialDayIdx ? potentialDayIdx : null,
    };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: IOwnProps): IDispatchProps => {
    return {
        moveEvent: (dayId: number) => {
            dispatch(moveEventToDay(ownProps.event.id, dayId, -1)); // always add the event to the end of the day
        },
        moveBackToWishlist: () => {
            dispatch(moveEventFromDayToWishlist(ownProps.event.id))
        },
        selectCard: (id: string) => {
            dispatch(SET_SELECTED_CARD({id, source: "card"}));
        }
    };
};

export const ConnectedEventCard = connect(mapStateToProps, mapDispatchToProps)(EventCard);
