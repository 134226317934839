import {IDistanceResponse, IGooglePlaceDetails} from "../types/types";
import {post} from "../util/parsers/backendParser";
import {IGooglePlaceSuggestion} from "../util/locations";
import {IBoard, IBoardLandingPreview} from "../redux/GlobalState";

const SERVER_ENDPOINT = process.env.REACT_APP_BACKEND;

export interface IPublicTrip {
    tripID: string;
    location: string;
    eventCount: number;
    dayCount: number;
    profilePicture: string;
}
export const getPublicTrips = (): Promise<IPublicTrip[]> => {
    return post(SERVER_ENDPOINT + "/info/public-trips")
        .then((response) => {
            console.log("TRIPS RESPONSE IS: ", response);
            if (response.type === "error") {
                throw new Error(response.error);
            } else {
                return response.trips;
            }
        });
}

export const getPublicBoards = (): Promise<IBoardLandingPreview[]> => {
    return post(SERVER_ENDPOINT + "/info/public-boards")
        .then((response) => {
            console.log("BOARDS RESPONSE IS: ", response);
            if (response.type === "error") {
                throw new Error(response.error);
            } else {
                return response.boards;
            }
        });
}

export const suggestPlaceFromQuery = (query: string): Promise<IGooglePlaceSuggestion[]> => {
    return post(SERVER_ENDPOINT + "/info/find-place", {query})
        .then((response) => {
            console.log("SUGGESTION RESPONSE IS: ", response);
            if (response.type === "error") {
                throw new Error(response.error);
            } else {
                return response.suggested.predictions;
            }
        });
}

export const suggestEventFromString = (query: string, location: {lat: number, long: number}): Promise<IGooglePlaceSuggestion[]> => {
    const locationString = location.lat.toString() + "," + location.long.toString();
    return post(SERVER_ENDPOINT + "/info/suggest-place", {query, location: locationString})
        .then((response) => {
            console.log("SUGGESTION RESPONSE IS: ", response);
            if (response.type === "error") {
                throw new Error(response.error);
            } else {
                return response.suggested ? response.suggested.predictions : [];
            }
        });
}


export const getPlaceDetailsFromPlaceId = (place_id: string): Promise<IGooglePlaceDetails> => {
    return post(SERVER_ENDPOINT + "/info/place-details", {place_id})
        .then((response) => {
            console.log("PLACE DETAILS RESPONSE IS: ", response);
            if (response.type === "error") {
                throw new Error(response.error);
            } else {
                return response.placeDetails;
            }
        });
}

export const boardToTrip = (boardID: string, owner: string): Promise<string> => {
    return post(SERVER_ENDPOINT + "/info/board-to-trip", {boardID, owner})
        .then((response) => {
            console.log("GOT NEW TRIP AND IT IS: ", response);
            if (response.type === "error") {
                throw new Error(response.error);
            } else {
                return response.tripID;
            }
        });
}
