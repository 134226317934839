import {IGlobalState} from "./GlobalState";
import * as _ from "lodash";

export const getEvents = (state: IGlobalState) => state.currentTrip.eventData;
export const getWishlist = (state: IGlobalState) => state.currentTrip.wishlist;
export const getDays = (state: IGlobalState) => state.currentTrip.days;

export const getActiveTags = (state: IGlobalState) => {
    const events = getEvents(state);
    const allEventtags = _.values(events).map((event) => event.tags);
    let allTags: string[] = [];
    allEventtags.forEach((tagList) => {
        allTags = allTags.concat(tagList);
    });
    return _.uniq(allTags);
}

export const getBoardSections = (state: IGlobalState) => {
    const events = state.currentBoard.eventData;
    const allEventTags = _.values(events).map((event) => event.tags);
    let allTags: string[] = [];
    allEventTags.forEach((tagList) => {
        allTags = allTags.concat(tagList);
    });
    return _.uniq(allTags);
}

export const eventsByTag = (state: IGlobalState) => {
    const events = state.currentBoard.eventData;
    const tagToEvents: {[tag: string]: string[]} = {};
    _.values(events).forEach((event) => {
        console.log("evaluating...", event);
        // always use the first tag as the selector
        const tag = _.isEmpty(event.tags) ? "other" : event.tags[0];
        if (tagToEvents[tag]) {
            tagToEvents[tag].push(event.id);
        } else {
            tagToEvents[tag] = [event.id];
        }
        console.log("value of map after ...", tagToEvents);

    });
    return tagToEvents;
}

export const eventsUnassignedDay = (state: IGlobalState) => {
    const events = state.currentTrip.wishlist;
    const days = state.currentTrip.days;
    const allEventsInDays = _.flatten(_.map(days, day => day.events));
    return _.difference(events, allEventsInDays);
}

export const assignedDayForEvent = (state: IGlobalState, eventID: string) => {
    const days = state.currentTrip.days;
    return _.find(days, (day) => day.events.includes(eventID));
}