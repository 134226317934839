export interface IEventData {
    id: string;
    name: string;
    image: string;
    description: string;
    source: IEventSource;
    source_url: string;
    location: ILocationData;
    tags: string[];
    duration: EVENT_DURATION;
}

export interface ILocationData {
    lat?: number;
    long?: number;
    name?: string;
    google_maps_url?: string;
    googlePlaceDetails?: IGooglePlaceDetails;
}

export interface IDayContent {
    name: string;
    description: string;
    events: string[];
}

export enum IEventSource {
    INSTAGRAM = "Instagram",
    MANUAL = "Manual",
    OTHER = "Other",
    SEARCH = "Search",
}

export enum EVENT_DURATION {
    NONE = "NoSelection",
    SHORT = "short",
    HALF_DAY = "half day",
    FULL_DAY = "full day",
}

export interface IGooglePlaceDetails {
    address?: string;
    location?: {
        lat: number;
        long: number;
    };
    name?: string;
    photos?: Array<{[id: string]: string}>;
    place_id?: string;
    types?: string[];
    url?: string;
    rating?: number;
}

export interface IDistanceResponse {
    car: {
        distance: {
            value: number;
            text: string;
        };
        duration: {
            value: number;
            text: string;
        };
    };
}
