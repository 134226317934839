import {createAction} from "typesafe-actions";
import {IEventData} from "../../types/types";

export const ADD_EVENT = createAction("ADD_EVENT", (resolve) => {
    return (event: IEventData) => resolve(event);
});

export const DELETE_EVENT = createAction("DELETE_EVENT", (resolve) => {
    return (eventID: number) => resolve(eventID);
});

export const HYDRATE_EVENTS = createAction("HYDRATE_EVENTS", (resolve) => {
    return (events: {[id: string]: IEventData}) => resolve(events);
})
