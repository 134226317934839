import {createAction} from "typesafe-actions";
import { IDayContent } from "../../types/types";

export const ADD_DAY = createAction("ADD_DAY", (resolve) => {
    return (name: string) => resolve(name);
});

export const SET_DAY_TITLE = createAction("SET_DAY_TITLE", (resolve) => {
    return (dayIndex: number, title: string) => resolve({dayIndex, title});
});

export const ADD_EVENT_TO_DAY = createAction("ADD_EVENT_TO_DAY", (resolve) => {
    return (eventId: string, dayIndex: number, indexDestination: number) => resolve({eventId, dayIndex, indexDestination});
});

export const DELETE_EVENT_FROM_ALL_DAYS = createAction("DELETE_EVENT_FROM_ALL_DAYS", (resolve) => {
    return (eventId: string) => resolve(eventId);
});

export const DELETE_EVENT_FROM_DAY = createAction("DELETE_EVENT_FROM_DAY", (resolve) => {
    return (eventId: string, dayIndex: number) => resolve({eventId, dayIndex});
});

export const REORDER_DAY_EVENT = createAction("REORDER_DAY_EVENT", (resolve) => {
    return (dayIndex: number, fromIdx: number, toIdx: number) => resolve({dayIndex, fromIdx, toIdx});
});

export const HYDRATE_DAY_CONTENT = createAction("HYDRATE_DAY_CONTENT", (resolve) => {
    return (days: IDayContent[]) => resolve(days);
})