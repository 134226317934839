import * as React from "react";
import {IDayContent} from "../types/types";
import {Button, H2} from "@blueprintjs/core";
import {connect} from "react-redux";
import {IGlobalState} from "../redux/GlobalState";
import * as _ from "lodash";
import {ConnectedDayCard} from "./DayCard";
import {Dispatch} from "redux";
import {ADD_DAY} from "../redux/actions/dayActions";
import {DayActionsType} from "../redux/reducers";
import Truncate from "react-truncate";

interface IProps {
    lineLimit: number;
    text: string;
    className?: string;
}
interface IState {
    expanded: boolean;
    truncated: boolean;
}
export class ExpandableText extends React.Component<IProps, IState> {
    state = {
        expanded: false,
        truncated: false,
    };

    render = () => {
        return (
            <div className={this.props.className}>
                <Truncate
                    lines={!this.state.expanded && this.props.lineLimit}
                    ellipsis={<span>... <a onClick={this.expand}>more</a></span>}
                    onTruncate={this.handleTruncate}
                    width={212}
                >
                    {this.state.expanded ?
                        this.props.text.split('\n').map((li, i, arr) => {
                            const line = <span key={i}>{li}</span>;

                            if (i === arr.length - 1) {
                                return line;
                            } else {
                                return [line, <br key={i + 'br'} />];
                            }
                        })
                        : this.props.text}

                </Truncate>
                {!this.state.truncated && this.state.expanded && (
                    <span> <a href='#' onClick={this.expand}>less</a></span>
                )}
            </div>
        )
    }

    expand = (event: any) => {
        event.preventDefault();

        this.setState({
            expanded: !this.state.expanded
        });
    }

    handleTruncate = (truncated: boolean) => {
        if (this.state.truncated !== truncated) {
            this.setState({
                truncated
            });
        }
    }

}
