import React from 'react';
import './App.scss';
import {MainScreen} from "./screens/MainScreen";
import {firestore} from "./util/firestore";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {UPDATE_USER} from "./redux/actions/sessionActions";
import {hydrateCurrentTrip} from "./redux/actions/batchedActions";
import { ITrip, setNextId } from './redux/GlobalState';
import {ConnectedLandingScreen} from "./screens/LandingScreen";
import {ConnectedWaitingScreen} from "./screens/WaitingScreen";
import { BrowserRouter as Router, Route } from "react-router-dom";
import {PrivacyPolicyScreen} from "./screens/PrivacyPolicyScreen";
import {BoardScreen} from "./screens/BoardScreen";
import {BoardLandingScreen} from "./screens/BoardLandingScreen";
import {ConnectedBookScreen} from "./screens/BookScreen";
import {ConnectedHostScreen} from "./screens/HostScreen";
import {AccountScreen} from "./screens/AccountScreen";

interface IDispatchProps {
    updateLoggedInUser(user: firebase.User | null): void;
    hydrateCurrentTrip(trip: ITrip): void;
}

interface IState {
    tripNotFound: boolean;
}
class AppComp extends React.Component<IDispatchProps, IState> {
    constructor(props: IDispatchProps) {
      super(props);
      this.state = {
        tripNotFound: false,
      }
    }

    async componentDidMount() {
        firestore.auth.onAuthStateChanged(user => {
            if (user) {
                console.log("authenticated with user in app: ", user);
                this.props.updateLoggedInUser(user);
            } else {
                this.props.updateLoggedInUser(null);
            }
        });
        console.log("authenticated with user in app: ");

        // const docId: string = window.location.pathname.split('/')[2];
        // const loadResp = await firestore.loadTrip(docId);
        // if (loadResp.trip) {
        //   setNextId(loadResp.trip.params.nextId);
        //   this.props.hydrateCurrentTrip(loadResp.trip);
        // } else {
        //   console.log(loadResp.err);
        //   this.setState({tripNotFound: true});
        // }
    }

    render() {
        return (
            <Router>
                <Route path={"/bl"} exact component={BoardLandingScreen} />
                <Route path={"/"} exact component={ConnectedLandingScreen} />
                <Route path={"/trip"} exact component={ConnectedWaitingScreen} />
                <Route path={"/trip/:id"} component={MainScreen}/>
                <Route path={"/board/:id"} component={BoardScreen}/>
                <Route path={"/book/:id"} component={ConnectedBookScreen}/>
                <Route path={"/host/:id"} component={ConnectedHostScreen}/>
                <Route path={"/privacy"} component={PrivacyPolicyScreen}/>
                <Route path={"/account"} exact component={AccountScreen} />
            </Router>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
    return {
        updateLoggedInUser: (user: any) => {
            dispatch(UPDATE_USER(user));
        },
        hydrateCurrentTrip: (trip: ITrip) => {
            dispatch(hydrateCurrentTrip(trip));
        },
    };
};

export default connect(null, mapDispatchToProps)(AppComp);