
import * as React from "react";
import { firestore } from "../util/firestore"
import { IGlobalState, ITrip, getNextId } from "../redux/GlobalState";
import { connect } from "react-redux";
import {Button, Callout, Dialog, FormGroup, InputGroup, Intent, Tag, Popover, PopoverInteractionKind, Position} from "@blueprintjs/core";
import * as _ from "lodash";
import "../styles/SaveButton.css";
import { Dispatch } from "redux";
import { SET_NEXT_ID } from "../redux/actions/paramsActions";
import {UPDATE_USER} from "../redux/actions/sessionActions";
import {GoogleButton} from "./GoogleButton";
import {FacebookButton} from "./FacebookButton";

interface IStateProps {
    currentTrip: ITrip;
    isLoggedIn: boolean;
    user: any;
    authLoading: boolean;
}
interface IDispatchProps {
    setLoggedInUser: (user: firebase.User) => void;
}
interface IOwnProps {
    onLoginComplete: (user: firebase.auth.UserCredential) => void;
    onLoginFailed: () => void;
}
type IProps = IStateProps & IOwnProps & IDispatchProps;

interface IState {
    isSignUp: boolean;
    email: string;
    password: string;
    loginError: string;
    isLoading: boolean;
}

class LoginBox extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            isLoading: props.authLoading,
            isSignUp: true,
            email: "",
            password: "",
            loginError: "",
        }
    }
    componentWillReceiveProps(nextProps: IProps) {
        if (nextProps.authLoading !== this.props.authLoading) {
            this.setState({isLoading: nextProps.authLoading});
        }
    }

    render() {
        return (
            <div>
                {this.renderSignIn()}
            </div>
        );
    }

    renderSignIn() {
        return (
            <div className={"login-dialog"}>
                <div className={"login-desc"}> Continue with </div>
                <GoogleButton onClick={this.handleGoogleLogin}/>
                <FacebookButton onClick={this.handleFacebookLogin} />
                {/*<hr className={"event-divider"}/>*/}
                {/*<FormGroup className="login-input">*/}
                    {/*<InputGroup*/}
                        {/*onChange={(event: any) => {*/}
                            {/*this.setState({email: event.target.value});*/}
                            {/*console.log("email: ", event.target.value);*/}
                        {/*}}*/}
                        {/*type="email"*/}
                        {/*placeholder="Email"*/}
                        {/*large*/}
                        {/*value={this.state.email}*/}
                    {/*/>*/}
                {/*</FormGroup>*/}
                {/*<FormGroup className="login-input">*/}
                    {/*<InputGroup*/}
                        {/*onChange={(event: any) => {*/}
                            {/*this.setState({password: event.target.value});*/}
                            {/*console.log("value: ", event.target.value);*/}
                        {/*}}*/}
                        {/*type="password"*/}
                        {/*placeholder="Password"*/}
                        {/*large*/}
                        {/*value={this.state.password}*/}
                    {/*/>*/}
                {/*</FormGroup>*/}
                <div className={"login-error"} style={_.isEmpty(this.state.loginError) ? {display: "none"} : {}}>
                    {_.isEmpty(this.state.loginError) ? null : <Callout title={"Something went wrong"}
                                                                        intent={Intent.WARNING}> {this.state.loginError} </Callout>}
                </div>
                {/*<div className={"submit-row"}>*/}
                    {/*<Tag round interactive className={"login-btn"} onClick={this.submitLogin}> {this.state.isSignUp ? "Sign Up" : "Log in"} </Tag>*/}
                    {/*<span> or </span>*/}
                    {/*<a onClick={() => {this.setState({isSignUp: !this.state.isSignUp})}}> {this.state.isSignUp ? "Log in" : "Sign Up"} </a>*/}
                {/*</div>*/}
            </div>
        );
    }
    submitLogin = () => {
        this.setState({loginError: ""});
        if (this.state.isSignUp) {
            firestore.createUser(this.state.email, this.state.password)
                .then((authUser) => {
                    this.props.onLoginComplete(authUser);
                })
                .catch((e) => {
                    this.setState({loginError: e.message});
                    this.props.onLoginFailed();
                });
        } else { // login
            firestore.signIn(this.state.email, this.state.password)
                .then((authUser) => {
                    this.props.onLoginComplete(authUser);
                })
                .catch((e) => {
                    this.setState({loginError: e.message});
                    this.props.onLoginFailed();
                });
        }
    }

    handleGoogleLogin = () => {
        firestore.doSignInWithGoogle()
            .then((socialAuthUser) => {
                console.log("logged in with this Google User: ", socialAuthUser);
                this.props.onLoginComplete(socialAuthUser);
            })
            .catch((error) => {
                this.setState({loginError: error.message});
                this.props.onLoginFailed();
            });
    }

    handleFacebookLogin = () => {
        firestore.doSignInWithFacebook()
            .then((socialAuthUser) => {
                console.log("logged in with this Facebook User: ", socialAuthUser);
                this.props.onLoginComplete(socialAuthUser);
            })
            .catch((error) => {
                this.setState({loginError: error.message});
                this.props.onLoginFailed();
            });
    }
}

const mapStateToProps = (state: IGlobalState): IStateProps => {
    return {
        currentTrip: state.currentTrip,
        isLoggedIn: Boolean(state.session.loggedInUser),
        user: state.session.loggedInUser,
        authLoading: state.session.authLoading,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
    return {
        setLoggedInUser: (user: firebase.User) => {
            dispatch(UPDATE_USER(user));
        },
    };
};

export const ConnectedLoginBox = connect(mapStateToProps, mapDispatchToProps)(LoginBox);